import React from 'react'

function List(props) {

	return(
		<div className="list">
			{props.children}
		</div>
	)
}

export default List