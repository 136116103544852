import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'
import {toast} from 'react-toastify'

import './Gallery.css'

const AdminGallery = props => {

	const [imgsList, setImgsList] = useState([])
	const [selectedImgs, setSelectedImgs] = useState([])

	const toggleSelectImg = img => {

		let imgIndex = selectedImgs.indexOf(img)

		if(imgIndex >= 0) {
			setSelectedImgs(selectedImgs.filter(selectedImg => {
				return selectedImg !== img
			}))
		} else {
			setSelectedImgs([...selectedImgs, img])
		}
	}

	const addSelectedImgsToGallery = () => {
		console.log("Add to gallery ", selectedImgs)

		if(selectedImgs.length > 0) {
			Axios.post("/api/gallery", {
				imgs: selectedImgs
			}).then(res => {
				toast.success("Les fichiers selectionnés ont bien été ajoutés à la galerie")

				setImgsList(imgsList.filter(img => {
					return !selectedImgs.includes(img)
				}))
				setSelectedImgs([])

			}).catch(err => {
				console.log(err)
			})
		}
	}

	useEffect(() => {

		Axios.get("/api/gallery/available").then(res => {
			setImgsList(res.data)
		}).catch(err => {
			console.log(err)
		})

	}, [])

	return (
		<>
			<div className="admin-players-page sidebar-container background-grey">

				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Galerie
					</h2>

					<div className="screenshots-controls">
						<div className="flex">
							<div className="btn" onClick={addSelectedImgsToGallery}>Ajouter</div>
						</div>
					</div>

					<div className="screenshots-list">

						{imgsList.map(img => {
							return (
								<div key={img.id} className={"screenshot-item" + (selectedImgs.includes(img) ? " is-selected" : "")}>
									<div>
										<img src={img.url} alt={"Img"} onClick={e => toggleSelectImg(img)} />
									</div>
								</div>
							)
						})}

					</div>

				</div>

			</div>
		</>
	)
}

export default AdminGallery