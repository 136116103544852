import React, { useEffect, useState } from 'react'
import Parallax from 'parallax-js'
import { Helmet } from "react-helmet"

import './Home.css'

import BGParallax7 from '../../assets/img/bg_parallax_7_compressed.png'

import Plane4 from '../../assets/img/plane_4_compressed.png'
import Plane5 from '../../assets/img/plane_5_compressed.png'  /* Frelon 1-7 I MerLinHier */

import DCSIcon from '../../assets/img/dcs-world-logo.svg'

import { FaDiscord as DiscordIcon } from 'react-icons/fa'
import List from '../../components/List/List'
import ListItem from '../../components/ListItem/ListItem'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Axios from 'axios'
import ServerStatus from '../../components/ServerStatus/ServerStatus'
import Card from '../../components/Card/Card'

import { FiArrowDown } from "react-icons/fi"

const Home3 = props => {

	const [serverData, setServerData] = useState({})
	const [A2APlayers, setA2APlayers] = useState([])
	const [A2GPlayers, setA2GPlayers] = useState([])

	const doScrolling = (elementY, duration) => {
		let startingY = window.pageYOffset;
		let diff = elementY - startingY;
		let start;

		window.requestAnimationFrame(function step(timestamp) {
			if (!start) start = timestamp;

			// ms since start of scrolling
			let time = timestamp - start;

			// Get percent of completion
			let percent = Math.min(time / duration, 1);

			window.scrollTo(0, startingY + diff * percent);

			// relaunch animation
			if (time < duration) {
				window.requestAnimationFrame(step);
			}
		})
  	}

	const handleScrollDownClick = () => doScrolling(document.getElementById('what-is-bfr').offsetTop, 300)

	useEffect(() => {

		Axios.get("/api/ranking/list/0?limit=5").then(res => {
			setA2APlayers(res.data)
		}).catch(err => {
			console.log(err)
		})

		Axios.get("/api/ranking/list/1?limit=5").then(res => {
			setA2GPlayers(res.data)
		}).catch(err => {
			console.log(err)
		})

		Axios.get('/api/server/basic-infos').then(res => {

			let server = res.data

			server.hoursPlayed = Math.round(Math.floor(server.timePlayed) / 3600)

			setServerData(server)

		}).catch(err => {
			console.log(err)
		})

		let scene = document.getElementById('parallax-banner');
		new Parallax(scene);

	}, [])

	return (
		<>
			<Helmet>
				<title>BFR | Bullseye Francophone | DCS World</title>
			</Helmet>

			<div className="home-page">

				<div className="parallax-banner" id="parallax-banner">

					<div data-depth="0.20"><div className="background" style={{backgroundImage: `url(${BGParallax7})`}}></div></div>
					<div data-depth="0.30"><div className="plane-parallax" id="plane-3" style={{backgroundImage: `url(${Plane4})`}}></div></div>
					<div data-depth="0.40"><h1 className="title">Bullseye Francophone</h1></div>
					<div data-depth="0.40"><h3 className="subtitle">Serveur PVE DCS World 24h/24</h3></div>

				</div>

				<div className="scroll-down-btn" onClick={handleScrollDownClick}>
					<div><FiArrowDown size={32} /></div>
				</div>

				<section className="container" id="what-is-bfr">

					<h1 className="section-title center"><div className="title">Qu'est ce que BFR ?</div></h1>

					<div className="content section-text">
						<p className="justify">
							A l'instar de sa définition, le Bullseye est un lieu de rencontre et de partage ayant pour but de proposer aux pilotes DCS World un point de rassemblement.
							BFR n'a pas vocation à devenir une escadrille, son crédo restera de rassembler une communauté autour d'une passion commune.
						</p>
						<br />
						<p>
							A travers son <a target="_blank" rel="nofollow noreferrer" href="https://discord.gg/8mpyQxPaZf">Discord</a>, et son serveur, BFR vous propose :<br />
							- un serveur PVE 24h/24,<br />
							- une présence régulière de contrôleurs aériens humain (AWACS - ATC - JTAC)<br />
							- des events (tournois Dogfights, campagne, formations, etc...)<br />
							- des documentations<br />
							- des statistiques individuelles<br />
						</p>
						<br />

						<p className="justify">
							L'accent est mis sur les communications vocales et nous conseillons vivement d'utiliser <Link to="/infos-server#srs">SRS (SimpleRadio Standalone)</Link>, afin d'offrir la plus grande immersion possible à tous les pilotes.
						</p>
					</div>

					<div className="content" id="bfr-stats">

						<div className="stats-list">

							<div className="stat-item">
								<div className="stat-stat">{serverData.count}</div>
								<div className="stat-label">Joueurs</div>
							</div>

							<div className="stat-item">
								<div className="stat-stat">{serverData.hoursPlayed}</div>
								<div className="stat-label">Heures jouées</div>
							</div>

							<div className="stat-item">
								<div className="stat-stat">{serverData.totalKills}</div>
								<div className="stat-label">Éliminations</div>
							</div>

						</div>

					</div>

				</section>

				<section className="banner" id="all-section-banner">
					<div className="background" style={{ backgroundImage: `url(${Plane5})` }}></div>
				</section>

				<section id="all-section">

                    {/*
					<section className="container" id="ranking-section">

						<h1 className="section-title center"><Link to="/ranking" className="title black-color">Classement</Link></h1>

						<div className="content">

							<div className="A2A-ranking">
								<div className="title-2">Air/Air</div>

								<div className="ranking-item ranking-header">

									<div className="ranking-place">

									</div>

									<div className="player-name">
										Joueur
									</div>

									<div className="player-kills">
										Kills
									</div>

									<div className="player-looses">
										Morts
									</div>

									<div className="player-score">
										Score
									</div>

								</div>

								{A2APlayers.map((player, idx) => {
									let rankingPlace = idx + 1

									let playerClass = ""

									if(rankingPlace === 1)
										playerClass = " gold"
									else if(rankingPlace === 2)
										playerClass = " silver"
									else if(rankingPlace === 3)
										playerClass = " bronze"

									return (
										<Link to={"/player/" + player.uniqid} key={player.uniqid} className={"ranking-item" + playerClass}>

											<div className="ranking-place">
												{rankingPlace}
											</div>

											<div className="player-name">
												{player.name}
											</div>

											<div className="player-kills">
												{player.A2AKills}
											</div>

											<div className="player-looses">
												{player.totalLooses}
											</div>

											<div className="player-score">
												{player.score}
											</div>

										</Link>

									)
								})}

							</div>

							<div className="A2G-ranking">
								<div className="title-2">Air/Sol</div>

								<div className="ranking-item ranking-header">

									<div className="ranking-place"></div>

									<div className="player-name">
										Joueur
									</div>

									<div className="player-kills">
										Kills Air/Sol
									</div>

								</div>

								{A2GPlayers.map((player, idx) => {

									let rankingPlace = idx + 1

									let playerClass = ""

									if(rankingPlace === 1)
										playerClass = " gold"
									else if(rankingPlace === 2)
										playerClass = " silver"
									else if(rankingPlace === 3)
										playerClass = " bronze"

									return (
										<Link to={"/player/" + player.uniqid} key={player.uniqid} className={"ranking-item" + playerClass}>

											<div className="ranking-place">
												{rankingPlace}
											</div>

											<div className="player-name">
												{player.name}
											</div>

											<div className="player-kills">
												{player.A2GKills}
											</div>

										</Link>

									)
								})}

							</div>

						</div>

					</section>
                    */}
					<section className="container" id="gallery-section">

						<h1 className="section-title center"><Link to="/gallery" className="title black-color">Galerie</Link></h1>

						<div className="section-text center">
							Voici les dernières photos de la <Link to="/gallery">galerie</Link>
						</div>

						<div className="gallery-content">
							<Link to="/gallery" className="img1">
								<div className="background" style={{backgroundImage: "url('/api/gallery/latest/0')"}}></div>
							</Link>
							<Link to="/gallery" className="img2">
								<div className="background" style={{backgroundImage: "url('/api/gallery/latest/1')"}}></div>
							</Link>
							<Link to="/gallery" className="img3">
								<div className="background" style={{backgroundImage: "url('/api/gallery/latest/2')"}}></div>
							</Link>
						</div>

					</section>

					<section className="container" id="infos-section">

						<h1 className="section-title center"><Link to="/infos-server" className="title black-color">Infos Serveur</Link></h1>

						<div className="content">
							<div className="top">
								<ServerStatus />
								<div>Il y a actuellement {serverData?.players?.length ?? 0} joueurs en ligne</div>
							</div>
							<div>

							</div>
							<div>

								<Card noPadding title="Liste des ATC">
									<List>
										{serverData.lotAtc &&
											<>
												{serverData.lotAtc.atc.map((player, idx) => {
													return (
														<ListItem key={"atc-" + player} left={player} right="ATC" />
													)
												})}
												{serverData.lotAtc.awacs.map((player, idx) => {
													return (
														<ListItem key={"awacs-" + player} left={player} right="AWACS" />
													)
												})}
												{serverData.lotAtc.jtac.map((player, idx) => {
													return (
														<ListItem key={"jtac-" + player} left={player} right="JTAC" />
													)
												})}
											</>
										}
										{serverData.lotAtc && serverData.lotAtc.atc.length === 0 && serverData.lotAtc.awacs.length === 0 && serverData.lotAtc.jtac.length === 0 &&
											<ListItem left={"Aucun ATC connecté"} />
										}
									</List>
								</Card>

							</div>
						</div>

					</section>

				</section>

				<section className="container" id="see-soon-section">

					<div className="section-text">

						<div className="center" id="join-us">Rejoignez-nous</div>

						<div className="social-media">

							<a className="social-media-item" target="_blank" rel="nofollow noreferrer" href="https://discord.gg/8mpyQxPaZf">

								<div className="social-media-label">Discord</div>
								<div className="social-media-icon"><DiscordIcon size={40} /></div>

							</a>

							<a href="/infos-server#bfr-server" className="social-media-item">

								<div className="social-media-label">DCS World</div>
								<div className="social-media-icon"><img src={DCSIcon} alt="DCS Logo" /></div>

							</a>

						</div>

					</div>

					<div className="section-subtitle">À bientôt sur le serveur BFR !</div>

				</section>

			</div>

			<Footer />

		</>
	)
}

export default Home3