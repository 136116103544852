import React, { useEffect, useState } from 'react'
import { BiSun, BiMoon } from 'react-icons/bi'

import './DarkThemeSwitcher.css'

const DarkThemeSwitcher = props => {

	const [isDark, setIsDark] = useState(false)

	const switchDarkTheme = e => {
		setIsDark(!isDark)
	}

	useEffect(() => {

		let prevTheme = localStorage.getItem('theme')

		if(prevTheme === "dark")
			setIsDark(true)

	}, [])

	useEffect(() => {

		if(isDark) {
			document.querySelector("body").classList.add("dark-theme")
			localStorage.setItem("theme", "dark")
		} else {
			document.querySelector("body").classList.remove("dark-theme")
			localStorage.setItem("theme", "")
		}

	}, [isDark])

	return (
		<div className="dark-theme-switch-container">
			<div className="dark-theme-switch" onClick={switchDarkTheme}>
				<div className="switch-dot"></div>
				<div className="theme-switch-icon"><BiMoon size={20} style={{ color: "#e8e6e3" }} /></div>
				<div className="theme-switch-icon"><BiSun size={20} style={{ color: "#e5ff00" }} /></div>
			</div>
		</div>
	)
}

export default DarkThemeSwitcher