import React, { useRef, useState } from "react"

const GalleryItem = props => {

	const galleryItem = useRef(null)
	const [hovered, setHovered] = useState(false)
	const [opened, setOpened] = useState(false)

	const onMouseEnter = () => {
		setHovered(true)
	}

	const onMouseLeave = () => {
		setHovered(false)
	}

	const onClick = () => {
		setOpened(!opened)
		if(props.onClick)
			props.onClick(!opened, galleryItem)
	}

	return (
		<div
			ref={galleryItem}
			className={"gallery-item" + ((hovered && !opened) ? " is-hovered" : "") + (opened ? " is-opened" : "") + " " + (props.className ?? "")}
		>

			<div onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>

				<span className="author">{props.author}</span>

				<div className="background" style={{backgroundImage: `url(${props.img})`}}>
					<img src={props.img} alt={"Img from " + props.author} style={{ opacity: "0" /* "hidden"*/ }} />
				</div>
			</div>

		</div>
	)
}

export default GalleryItem