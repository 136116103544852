export const debounce = (func, wait, immediate) => {
    let timeout

    return (...args) => {
        let context = this

        let later = () => {
            timeout = null

            if (!immediate)
                func.apply(context, args)
        }

        let callNow = immediate && !timeout

        clearTimeout(timeout)

        timeout = setTimeout(later, wait)

        if (callNow)
            func.apply(context, args)
    }
}

export const formatTimestamp = timestamp => {

    var d = new Date(timestamp),
        months = '' + (d.getMonth() + 1),
        days = '' + d.getDate(),
        years = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

    if (months.length < 2)
        months = '0' + months;

    if (days.length < 2)
        days = '0' + days;

    if (hours < 10)
        hours = '0' + hours;

    if (minutes < 10)
        minutes = '0' + minutes;

    if (seconds < 10)
        seconds = '0' + seconds;

    return hours + ":" + minutes + ":" + seconds + " - " + days + "/" + months + "/" + years
}