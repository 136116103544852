import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import axios from 'axios'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { FaBan } from "react-icons/fa"

import './Player.css'
import Card from '../../components/Card/Card'
import List from '../../components/List/List'
import ListItem from '../../components/ListItem/ListItem'
import ListHeader from '../../components/ListHeader/ListHeader'
import AuthService from '../../services/AuthService'
import { Helmet } from 'react-helmet'
import Popup from '../../components/Popup/Popup'
import Input from '../../components/Input/Input'
import { toast } from 'react-toastify'

// Todo : add player states icon in div.post-icon   (banned, lotatc, etc ...)
function Player(props) {

    const [openedPopup, setOpenedPopup] = useState("")
    const [formData, setFormData] = useState({})

    const history = useHistory()

    const { playerId } = useParams()

    const [playerData, setPlayerData] = useState({})

    const goBack = () => {
        history.goBack()
    }

    const formatTimestampDate = timestamp => {
        let intTimestamp = Math.floor(timestamp)

        let hours = (intTimestamp - (intTimestamp % 3600)) / 3600
        intTimestamp -= hours * 3600
        if (hours < 10)
            hours = "0" + hours

        let minutes = (intTimestamp - (intTimestamp % 60)) / 60
        intTimestamp -= minutes * 60
        if (minutes < 10)
            minutes = "0" + minutes

        let seconds = intTimestamp
        if (seconds < 10)
            seconds = "0" + seconds

        return hours + ":" + minutes + ":" + seconds
    }

    const formatStrDate = strDate => {
        return new Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            formatMatcher: "basic"
        }).format(new Date(strDate))
    }

    const handleFormChange = (inputName, val) => {
        setFormData(oldFormData => ({ ...oldFormData, [inputName]: val }))
    }

    const banPlayer = () => {
        console.log("Ban player")

        const {
            ucid
        } = playerData

        if (ucid) {
            let reason = formData["ban-message"]
            let banMonths = formData["ban-months"]
            let banDays = formData["ban-days"]
            let banHours = formData["ban-hours"]

            let time = 0
            let permBan = false

            if (banMonths) {
                time += banMonths * (60 * 60 * 24 * 30)
            }

            if (banDays) {
                time += banDays * (60 * 60 * 24)
            }

            if (banHours) {
                time += banHours * (60 * 60)
            }

            if (time === 0) {
                permBan = true
            }

            axios.post("/api/server/ban-player", {
                ucid,
                reason,
                time,
                permBan
            }).then(res => {
                toast.success("Le joueur à bien été banni")
                setOpenedPopup("")
            }).catch(err => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        axios.get("/api/players/" + playerId).then(res => {

            let { player } = res.data

            let planesKey = Object.keys(player.planes)

            let totalTimePlayed = 0
            let totalTimeFlyed = 0

            let totalUnitsKill = 0
            let totalDeaths = 0

            for (let i = 0; i < planesKey.length; i++) {
                let planeKey = planesKey[i];
                let plane = player.planes[planeKey]

                if (plane && planeKey !== null && planeKey !== "null") {

                    player.planes[planeKey].time_played = formatTimestampDate(player.planes[planeKey].total)
                    player.planes[planeKey].time_flyed = formatTimestampDate(player.planes[planeKey].inAir)

                    totalTimePlayed += plane.total
                    totalTimeFlyed += plane.inAir

                    for (const weaponIdx in plane.weapons) {
                        if (plane.weapons.hasOwnProperty(weaponIdx)) {
                            const weapon = plane.weapons[weaponIdx];
                            totalUnitsKill += weapon.kills ?? 0
                            totalUnitsKill += weapon.groundKills ?? 0
                        }
                    }

                    totalDeaths += plane.losses.crash
                }
            }

            player.time_played = formatTimestampDate(totalTimePlayed)
            player.time_flyed = formatTimestampDate(totalTimeFlyed)
            player.units_kills = totalUnitsKill
            player.deaths = totalDeaths
            player.ratio = (totalUnitsKill / totalDeaths).toFixed(2)

            if (isNaN(player.ratio))
                player.ratio = 0
            else if (!isFinite(player.ratio))
                player.ratio = totalUnitsKill

            player.join_date = formatStrDate(player.stats.join_date)
            player.last_date = formatStrDate(player.stats.last_date)

            player.stats.teamKills = player.teamKills ? player.teamKills.length : 0

            setPlayerData(player)
        }).catch(e => {
            console.log(JSON.stringify(e))
        })
    }, [playerId])

    if (!playerData.stats) {
        playerData.stats = {}
        playerData.planes = {}
    }

    return (
        <>
            <Helmet>
                <title>{playerData.name ? "Statistiques de " + playerData.name + " | BFR" : "Statistiques du serveur BFR"}</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Popup title="Bannir le joueur" show={(openedPopup === "ban-popup")} hidePopup={() => setOpenedPopup(false)}>
                <div className="flex flex-column space-around flex-1">
                    <div>
                        <Input label="Message" defaultValue={formData["ban-message"]} onChange={(val) => handleFormChange("ban-message", val)} />
                    </div>

                    <div>
                        <div className="center">Laisser un temps vide pour un ban permanent</div>
                        <div className="inputs-row">
                            <Input label="Mois" type="number" defaultValue={formData["ban-months"]} onChange={(val) => handleFormChange("ban-months", val)} />
                            <Input label="Jours" type="number" defaultValue={formData["ban-days"]} onChange={(val) => handleFormChange("ban-days", val)} />
                            <Input label="Heures" type="number" defaultValue={formData["ban-hours"]} onChange={(val) => handleFormChange("ban-hours", val)} />
                        </div>
                    </div>

                    <div className="btn-group center">
                        <div className="btn btn-danger btn-small" onClick={(e) => banPlayer()}>Bannir</div>
                        <div className="btn btn-success btn-small" onClick={(e) => setOpenedPopup(false)}>Annuler</div>
                    </div>
                </div>
            </Popup>

            <div className="player-page background-grey container page-padding">

                <h1 className="section-title">
                    <div onClick={goBack} className="pre-icon pointer"><IoIosArrowBack /></div>
                    <div className="center title">{playerData.name} {AuthService.getCurrentUser() ? " - " + playerData.ucid : ""}</div>
                    <div className="post-icon pointer" onClick={e => setOpenedPopup("ban-popup")}><FaBan size={25} /></div>
                </h1>

                <div className="cards-container">

                    <div className="stats-card">
                        <Card noPadding title="Stats">

                            <List>
                                <ListItem left="Temps joué" right={playerData.time_played ? playerData.time_played : "0"} />
                                <ListItem left="Temps en vol" right={playerData.time_flyed ? playerData.time_flyed : "0"} />
                                <ListItem left="Ratio" right={playerData.ratio ? playerData.ratio : "0"} />
                                <ListItem left="Unités tuées" right={playerData.units_kills ? playerData.units_kills : "0"} />
                                <ListItem left="Morts" right={playerData.deaths ? playerData.deaths : "0"} />
                                <ListItem left="Alliés tués" right={playerData.stats.teamKills ? playerData.stats.teamKills : "0"} />
                                <ListItem left="Pénalités" right={playerData.penalties ? playerData.penalties : "0"} />
                                <ListItem left="Première connexion" right={playerData.join_date ? playerData.join_date : "0"} />
                                <ListItem left="Dernière connexion" right={playerData.last_date ? playerData.last_date : "0"} />
                            </List>

                        </Card>
                    </div>

                    <div className="names-card">
                        <Card noPadding title="Historique des noms">
                            <List>
                                {Object.keys(playerData.namesHistory ?? {}).map(idx => (
                                    <ListItem key={"name-" + idx} left={playerData.namesHistory[idx]} />
                                ))}
                            </List>
                        </Card>
                    </div>

                    <div className="planes-card">
                        <Card noPadding title="Avions">
                            <List>
                                <ListHeader left="Nom" middle="Temps de vol" right="" />
                                {Object.keys(playerData.planes).length === 0 &&
                                    <ListItem left="Ce joueur n'a pas encore utilisé d'avions" />
                                }
                                {Object.keys(playerData.planes).map((idx) => {
                                    if (!playerData.planes[idx].planeName) {
                                        return null
                                    }
                                    return (
                                        <ListItem key={idx} to={"/player/" + playerId + "/planes/" + idx} left={playerData.planes[idx].planeName} middle={playerData.planes[idx].time_flyed} right={<IoIosArrowForward />} />
                                    )
                                })}
                            </List>
                        </Card>
                    </div>

                    <div className="team-kills-card">
                        <Card noPadding title="Alliés tués">
                            <List>
                                <ListHeader left="Victime" middle="Arme" right="" />
                                {playerData.teamKills !== undefined && playerData.teamKills.length === 0 &&
                                    <ListItem left="Ce joueur n'a pas tué d'alliés" />
                                }
                                {playerData.teamKills && playerData.teamKills.map((teamKill) => {
                                    return (
                                        <ListItem key={teamKill._id} to={"/player/" + playerId + "/team-kills"} left={teamKill.targetIsAi ? "AI" : teamKill?.toPlayer?.name} middle={teamKill.weapon} right={<IoIosArrowForward />} />
                                    )
                                })}
                            </List>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Player