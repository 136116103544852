import React from "react"
import { Link } from 'react-router-dom'

import './Footer.css'

const Footer = props => {
	return (
		<footer className="footer container">

			<div className="left">
				<Link to="/login">Se Connecter</Link>
			</div>

			<div className="right">
				{/*<Link to="/legal-mentions">Mentions Légales</Link> */}
			</div>

		</footer>
	)
}
export default Footer