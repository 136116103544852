import React from "react"

import './CoalitionDisplay.css'

const CoalitionDisplay = props => {

	let coalitionName = ""

	if(props.coalition === "blue" || props.coalition === 2 || props.coalition === "2") {
		coalitionName = "Bleu"
	}
	else if(props.coalition === "red" || props.coalition === 1 || props.coalition === "1") {
		coalitionName = "Rouge"
	}
	else if(props.coalition === "neutral" || props.coalition === "spectator" || props.coalition === 0 || props.coalition === "0") {
		coalitionName = "Spectateur"
	}

	return (
		<div className={"coalition-display annotation coalition-" + props.coalition}>
			{coalitionName}
		</div>
	)
}
export default CoalitionDisplay