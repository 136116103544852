import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'

const AdminPlayers = props => {

	const [players, setPlayers] = useState([])

	useEffect(() => {

		Axios.get("/api/players?limit=0").then(res => {
			setPlayers(res.data.players)
		}).catch(err => {
			console.log(err)
		})

	}, [])

	return (
		<>
			<div className="admin-players-page sidebar-container background-grey">

				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Liste des joueurs - {players.length} joueurs
					</h2>

					<div className="players-list">

						<table className="table">
							<thead>
								<tr>
									<th className="small"></th>
									<th className="left">Nom</th>
									<th></th>
									<th className="table-actions nostretch"></th>
								</tr>
							</thead>

							<tbody>

								{players.map((player, idx) => {
									return (
										<tr key={player.uniqid}>
											<td className="small">{idx + 1}</td>
											<td className="left">{player.name}</td>
											<td></td>
											<td className="table-actions nostretch">
												<Link to={"/player/" + player.uniqid} className="btn btn-small btn-success">Voir le joueur</Link>
											</td>
										</tr>
									)
								})}

							</tbody>
						</table>

					</div>

				</div>

			</div>
		</>
	)
}

export default AdminPlayers