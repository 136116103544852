import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import './Tacview.css'

const Tacview = props => {

	const [tacviews, setTacview] = useState([])

	const bytesToSize = bytes => {
		let sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];

		if (bytes === 0) return '0 o';

		let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}

	const formatDate = date => {
		var d = new Date(date),
			months = '' + (d.getMonth() + 1),
			days = '' + d.getDate(),
			years = d.getFullYear(),
			hours = d.getHours(),
			minutes = d.getMinutes(),
			seconds = d.getSeconds();

		if (months.length < 2)
			months = '0' + months;

		if (days.length < 2)
			days = '0' + days;

		if (hours < 10)
			hours = '0' + hours;

		if (minutes < 10)
			minutes = '0' + minutes;

		if (seconds < 10)
			seconds = '0' + seconds;

		return hours + ":" + minutes + ":" + seconds + " - " + days + "/" + months + "/" + years
	}

	useEffect(() => {
		axios.get('/api/tacview/list').then(res => {
			setTacview(res.data)
		}).catch(err => {
			if(err?.response?.data?.error)
				toast.error(err.response.data.error)
			else
				toast.error("Une erreur est survenue")
		})
	}, [])

	return (
		<>
			<Helmet>
				<title>Tacview BFR</title>
			</Helmet>

			<div className="tacview-page center page-padding">

				<div className="container">

					<h1 className="section-title">
						<div className="center title">Tacview</div>
					</h1>

					<p className="content">
						Les fichiers Tacview sont disponibles apres chaque redémarrage du serveur DCS.<br />
						Pour voir les horaires des redémarrages, accedez à la page <Link to="/status">status</Link>.
					</p>

					<div className="files-list-container content">

						<div className="files-header">

							<div>
								<div className="file-name">Nom</div>
								<div className="file-size">Taille</div>
								<div className="file-date">Date</div>
							</div>

						</div>

						<div className="files-list">

							{tacviews.map((file, idx) => {
								return (
									<a key={idx} href={axios.defaults.baseURL + "/api/tacview/download/" + encodeURI(file.filename)} rel="noreferrer nofollow" target="_blank" download={file.filename} className="file-item">
										<div className="file-name">{file.filename}</div>
										<div className="file-size">{bytesToSize(file.size)}</div>
										<div className="file-date">{formatDate(file.createdAt)}</div>
									</a>
								)
							})}

						</div>

					</div>

				</div>

			</div>
		</>
	)
}

export default Tacview