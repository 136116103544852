import './App.css'
import {
    Switch,
    Route,
    useLocation,
    useHistory
} from "react-router-dom"
import axios from 'axios'

import Home from './pages/Home/Home'
/*import Ranking from './pages/Ranking/Ranking'*/
import Tacview from './pages/Tacview/Tacview'
import ServerInfos from './pages/ServerInfos/ServerInfos'
import Stats from './pages/Stats/Stats'
import Calendar from './pages/Calendar/Calendar'
import Status from './pages/Status/Status'
import Login from './pages/Login/Login'
import Gallery from "./pages/Gallery/Gallery"
import Player from './pages/Player/Player'
import PlayerPlanes from './pages/PlayerPlanes/PlayerPlanes'
import PlayerTeamKills from './pages/PlayerTeamKills/PlayerTeamKills'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import AdminRoute from './components/AdminRoute/AdminRoute'
import UserDashboard from './pages/User/Dashboard/Dashboard'
import AdminDashboard from './pages/Admin/Dashboard/Dashboard'
import AdminChat from './pages/Admin/Chat/Chat'
import AdminMissions from "./pages/Admin/Missions/Missions"
import AdminLogs from './pages/Admin/Logs/Logs'
import AdminSettings from './pages/Admin/Settings/Settings'
import AdminPlayers from './pages/Admin/Players/Players'
import AdminGallery from './pages/Admin/Gallery/Gallery'

import AuthService from './services/AuthService'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminBans from './pages/Admin/Bans/Bans'
import AdminRestarts from './pages/Admin/Restarts/Restarts'
import AdminSRS from './pages/Admin/SRS/SRS'
import AdminMap from './pages/Admin/Map/Map'
import Header from './components/Header/Header'
import Logs from './pages/Logs/Logs'
import TournamentsList from './pages/Tournaments/List/Tournaments'
import TournamentView from './pages/Tournaments/View/TournamentView'
import Error404 from './pages/Errors/404'
import TournamentRegister from './pages/Tournaments/Register/TournamentRegister'
import TournamentCreate from './pages/Tournaments/Create/TournamentCreate'
import { useEffect } from 'react'
import ReactGA from "react-ga4"

window.baseURL = "http://localhost:3002"

if (process.env.NODE_ENV !== 'development') {
    window.baseURL = "https://bullseye-francophone.fr"
}

axios.defaults.baseURL = window.baseURL

if (AuthService.checkLogged()) // Relog after reloading the page
    AuthService.renewToken()

ReactGA.initialize(process.env.REACT_APP_GA_ID)

ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })

function App() {

    const { listen } = useHistory()

    const query = new URLSearchParams(useLocation().search)

    const fullscreen = query.get("fullscreen")

    useEffect(() => {

        let prevTheme = localStorage.getItem('theme')

        if (prevTheme === "dark")
            document.querySelector("body").classList.add("dark-theme")
        else
            document.querySelector("body").classList.remove("dark-theme")

        // for Google Analytics
        const unlisten = listen((location, action) => {
            if (!window.gtag) return

            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
            //ReactGA.pageview(window.location.pathname + window.location.search)
            //console.log(window.location.pathname + window.location.search)
        })

        return () => {
            unlisten()
        }
    }, [])

    return (
        <>

            {!fullscreen && <Header />}

            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/stats" component={Stats} />
                <Route exact path="/calendar" component={Calendar} />
                {/* <Route exact path="/ranking" component={Ranking} /> */}
                <Route exact path="/tacview" component={Tacview} />
                <Route exact path="/infos-server" component={ServerInfos} />
                <Route exact path="/status" component={Status} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/tournaments" component={TournamentsList} />
                <Route exact path="/formations" component={TournamentsList} />

                <AdminRoute exact path="/tournaments/new" component={TournamentCreate} />

                <Route exact path="/tournaments/:tournamentId/register" component={TournamentRegister} />
                <Route exact path="/tournaments/:tournamentId" component={TournamentView} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/player/:playerId" component={Player} />
                <Route exact path="/player/:playerId/planes/:planeId" component={PlayerPlanes} />
                <Route exact path="/player/:playerId/team-kills" component={PlayerTeamKills} />

                {/* Todo */}
                <Route exact path="/logs" component={Logs} />

                <PrivateRoute exact path="/user/dashboard" component={UserDashboard} />

                <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
                <AdminRoute exact path="/admin/chat" component={AdminChat} />
                <AdminRoute exact path="/admin/bans" component={AdminBans} />
                <AdminRoute exact path="/admin/players" component={AdminPlayers} />
                <AdminRoute exact path="/admin/gallery" component={AdminGallery} />
                <AdminRoute exact path="/admin/missions" component={AdminMissions} />
                <AdminRoute exact path="/admin/logs" component={AdminLogs} />
                <AdminRoute exact path="/admin/restarts" component={AdminRestarts} />
                <AdminRoute exact path="/admin/srs" component={AdminSRS} />
                <AdminRoute exact path="/admin/map" component={AdminMap} />
                <AdminRoute exact path="/admin/logs/:logName" component={AdminLogs} />
                <AdminRoute exact path="/admin/settings/:settingsId" component={AdminSettings} />

                <Route path="/" component={Error404} />
            </Switch>

            <ToastContainer />
        </>
    );
}

export default App;
