import React, { useEffect, useState } from "react"
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'
import Card from '../../../components/Card/Card'
import GameChat from '../../../components/GameChat/GameChat'
import axios from 'axios'

const AdminChat = props => {

	const [serverData, setServerData] = useState({})

	useEffect(() => {

		axios.get("/api/server/full-infos").then(res => {
			setServerData(res.data)
		}).catch(err => {
			console.log(err)
		})

	}, [])

	return (
		<>
			<div className="sidebar-container admin-dashboard-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Messages
					</h2>

					<div className="cards-container">

						<Card title="Chat" className="flex-1" noPadding>
							<GameChat maxHeight="800px" hostName={(serverData.serverInfos ? serverData.serverInfos.hostName : "")} />
						</Card>

					</div>

				</div>

			</div>
		</>
	)
}
export default AdminChat