import React, { useEffect, useState } from "react"
import axios from  "axios"
import { MdSend } from 'react-icons/md'
import socketIOClient from "socket.io-client"

import './GameChat.css'


const GameChat = props => {

	const [chatHistory, setChatHistory] = useState([])
	const [messageValue, setMessageValue] = useState("")

	const sendMessage = (e) => {
		e.preventDefault()

		let filteredMessageValue = messageValue.trim()

		if(filteredMessageValue) {

			axios.post("/api/server/game-chat", {
				message: filteredMessageValue
			}).then(res => {
				setMessageValue("")
			}).catch(err => {
				console.log(err)
			})
		}
	}

	const formatStrDate = strDate => {
		return new Intl.DateTimeFormat("fr-FR", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			formatMatcher: "basic"
		}).format(new Date(strDate))
	}

	const reloadChatHistory = newChatHistory => {
		setChatHistory(newChatHistory)
	}

	const addChatMessage = message => {
		setChatHistory(oldChatHistory => [...oldChatHistory, message])
	}

	useEffect(() => {
		const socket = socketIOClient(axios.defaults.baseURL)

		socket.on("new-game-message", addChatMessage)
		socket.on("new-chat-history", reloadChatHistory)

		axios.get('/api/server/game-chat').then(res => {
			setChatHistory(res.data.chat)
		}).catch(err => {
			console.log(err)
		})

		return function() {
			socket.disconnect()
		}

	}, [])

	return (
		<div className="chat">

			<div className="chat-message-list" style={{maxHeight: (props.maxHeight ? props.maxHeight : "400px")}}>
				{chatHistory && chatHistory.map((chatMessage, idx) => {
					return (
						<div key={idx} className={"chat-message-item " + (chatMessage.fromPlayerName === props.hostName ? "from-me" : "")}>
							<div className="message-header">
								<span className="message-author">{chatMessage.fromPlayerName}</span>
								<span className="message-sent-at">{formatStrDate(chatMessage.sentAt * 1000)}</span>
							</div>

							<div className="message-content">
								{chatMessage.message}
							</div>
						</div>
					)
				})}
			</div>

			<div className="chat-message-form" onSubmit={(e) => sendMessage(e)}>

				<form>
					<input type="text" name="message" placeholder="Message" value={messageValue} onChange={e => setMessageValue(e.target.value)}/>
					<button type="submit"><MdSend size={24} /></button>
				</form>

			</div>

		</div>
	)
}

export default GameChat