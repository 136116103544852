import React from "react"

import './Textarea.css'

const Textarea = props => {
	const [value, setValue] = React.useState(props.defaultValue || "");
	const [focused, setFocused] = React.useState(false)

	const handleChange = (e) => {
		let {value} = e.target
		setValue(value)
		if(props.onChange) {
			props.onChange(value, props?.name)
		}
	}

	return (
		<div className={"form-group" + (focused ? ' is-focused' : '') + (value.length > 0 ? ' has-content' : '')}>
			<label htmlFor={props.label}>{props.label}</label>

			<textarea onChange={handleChange} id={props.label} name={props.label} onFocus={(e) => setFocused(true)} onBlur={(e) => setFocused(false)}>{props.value}</textarea>

			<span className="error"></span>
		</div>
	)
}

export default Textarea