import React, { useState } from "react"
import { Link } from 'react-router-dom'

const DropdownMenu = props => {

	const [itemsHeight, setItemsHeight] = useState(0)

	const onLinkTitleClick = (e) => {
		if(itemsHeight === 0)
			e.preventDefault()
	}

	const handleEnter = () => {
		setItemsHeight("auto")
	}

	const handleLeave = () => {
		setItemsHeight(0)
	}

	return (
		<div className={"dropdown-menu" + (itemsHeight === "auto" ? " is-opened" : "") + (props.className ? " " + props.className : "")} onMouseLeave={handleLeave} onMouseEnter={handleEnter}>
			{props.titleTo &&
				<Link className="dropdown-title" onClick={onLinkTitleClick} to={props.titleTo}>{props.title}</Link>
			}
			{!props.titleTo &&
				<div className="dropdown-title">{props.title}</div>
			}
			<div className="dropdown-items">
				<div>
					{props.children}
				</div>
			</div>
		</div>
	)
}
export default DropdownMenu