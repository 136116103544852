import React, { useEffect } from 'react'
import { Redirect, Route, useHistory } from "react-router-dom"
import AuthService from "../../services/AuthService"

function PrivateRoute({component: Component, ...rest}) {

	const history = useHistory()

	useEffect(() => {

		const handleLogout = () => {
			console.log("Handle logout")
			history.push('/login')
		}

		AuthService.addEventListener("logout", handleLogout)

		return function () {
			AuthService.removeEventListener("logout", handleLogout)
		}

	}, [history])

	return (
		<Route
			{...rest}
			render={props =>
				AuthService.getCurrentUser() ? (
					<Component {...props} />
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	)
}

export default PrivateRoute