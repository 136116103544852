import React from "react"
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'

import './Restarts.css'

const AdminRestarts = props => {

	return (
		<>
			<div className="sidebar-container admin-restarts-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Redémarrages
					</h2>

					<div className="restarts-controls">

						<div className="btn-group">
							<div className="btn btn-small">Ajouter</div>
							<div className="btn btn-small btn-warning">Redémarrer</div>
						</div>

					</div>

					<div className="restarts-list">

						<table className="table">

							<thead>
								<tr>
									<th></th>
									<th>Heure</th>
									<th>Status</th>
									<th className="table-actions nostretch">Actions</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>1</td>
									<td>02:00</td>
									<td>En Attente</td>
									<td className="table-actions nostretch">
										<div className="btn-group center">
											<div className="btn btn-small btn-success">Modifier</div>
											<div className="btn btn-small btn-danger">Supprimer</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>2</td>
									<td>08:00</td>
									<td>En Attente</td>
									<td className="table-actions nostretch">
										<div className="btn-group center">
											<div className="btn btn-small btn-success">Modifier</div>
											<div className="btn btn-small btn-danger">Supprimer</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>3</td>
									<td>15:00</td>
									<td>En Attente</td>
									<td className="table-actions nostretch">
										<div className="btn-group center">
											<div className="btn btn-small btn-success">Modifier</div>
											<div className="btn btn-small btn-danger">Supprimer</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>4</td>
									<td>20:00</td>
									<td>En Attente</td>
									<td className="table-actions nostretch">
										<div className="btn-group center">
											<div className="btn btn-small btn-success">Modifier</div>
											<div className="btn btn-small btn-danger">Supprimer</div>
										</div>
									</td>
								</tr>
							</tbody>

						</table>

					</div>

				</div>

			</div>
		</>
	)
}

export default AdminRestarts