import React, { useEffect, useState } from 'react'

import './Gallery.css'

import GalleryItem from '../../components/GalleryItem/GalleryItem'
import Axios from 'axios'
import { Helmet } from 'react-helmet'

const Gallery = props => {

	const [imgs, setImgs] = useState([])

	const doScrolling = (elementY, duration) => {
		let startingY = window.pageYOffset;
		let diff = elementY - startingY;
		let start;

		window.requestAnimationFrame(function step(timestamp) {
			if (!start) start = timestamp;

			// ms since start of scrolling
			let time = timestamp - start;

			// Get percent of completion
			let percent = Math.min(time / duration, 1);

			window.scrollTo(0, startingY + diff * percent);

			// relaunch animation
			if (time < duration) {
				window.requestAnimationFrame(step);
			}
		})
  	}

	const onClick = (newState, galleryItem) => {

		if(newState && galleryItem) {
			let elementY = galleryItem.current.offsetTop - 100

			doScrolling(elementY, 300)
		}
	}

	useEffect(() => {

		Axios.get("/api/gallery").then(res => {
			setImgs(res.data)
		}).catch(err => {
			console.log(err)
		})

	}, [])

	return (
		<>
			<Helmet>
				<title>Galerie BFR</title>
			</Helmet>

			<div className="gallery-page page-padding">

				<div className="container">

					<h1 className="section-title center"><div className="center title">Galerie</div></h1>

					<div className="content center">
						<p>Les photos de la galerie sont selectionnées parmis celles partagées sur le discord BFR, venez poster vos meilleures captures pour les voir s'afficher ici !</p>
					</div>

					<div id="gallery-grid">

						{imgs.map((img) => {
							return (
								<GalleryItem onClick={onClick} key={img._id} author={img.author.name} img={Axios.defaults.baseURL + "/api/gallery/" + img.safeName} />
							)
						})}

					</div>

				</div>

			</div>
		</>
	)
}

export default Gallery