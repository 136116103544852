import React, { useEffect, useState } from "react"
import Card from '../../../components/Card/Card'
import CoalitionDisplay from '../../../components/CoalitionDisplay/CoalitionDisplay'
import List from '../../../components/List/List'
import ListHeader from '../../../components/ListHeader/ListHeader'
import ListItem from '../../../components/ListItem/ListItem'
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'
import { FiMoreVertical } from 'react-icons/fi'
import axios from "axios"
import socketIOClient from "socket.io-client"
import Input from '../../../components/Input/Input'

import './Dashboard.css'
import GameChat from '../../../components/GameChat/GameChat'
import Popup from '../../../components/Popup/Popup'


function AdminDashboard(props) {

	// Todo : make ban system
	// Todo : make private chat system

	const [selectedPlayerId, setSelectedPlayerId] = useState(null)
	const [serverData, setServerData] = useState({})
	const [openedPopup, setOpenedPopup] = useState(false)
	const [formsData, setFormsData] = useState({})

	const selectPlayer = (e, player) => {
		e.preventDefault()
		e.stopPropagation()

		const {
			pageX,
			pageY
		} = e

		let toSet = { x: pageX, y: pageY, ucid: player.ucid, id: player.id }

		setSelectedPlayerId(toSet)

		document.addEventListener('click', handleDocumentClick)
	}

	const handleDocumentClick = () => {
		if(!window.openedPopup) {
			setSelectedPlayerId(null)
			document.removeEventListener('click', handleDocumentClick)
		}
	}

	const customSetOpenedPopup = toOpenedPopup => {
		setOpenedPopup(toOpenedPopup)
		window.openedPopup = toOpenedPopup
	}

	const openPopup = (e, popupName) => {
		e.preventDefault()
		e.stopPropagation()
		customSetOpenedPopup(popupName)
	}

	const reloadServerData = () => {

		axios.get("/api/server/full-infos").then(res => {
			setServerData(res.data)
		}).catch(err => {
			console.log(err)
		})
	}

	// Todo : change kick from playerid to playerucid
	const kickPlayer = message => {
		axios.post("/api/server/kick-player", {
			playerId: selectedPlayerId.id,
			message
		}).then(res => {
			handleFormChange("kick-" + selectedPlayerId.id, "")
			setSelectedPlayerId(null)
			customSetOpenedPopup(false)
		}).catch(err => {
			console.log(err)
		})
	}

	const handleFormChange = (inputName, val) => {
		setFormsData(oldFormsData => ({...oldFormsData, [inputName]: val}))
	}

	useEffect(() => {

		reloadServerData()

		const socket = socketIOClient(axios.defaults.baseURL)

		socket.on("online-players", reloadServerData)

		return function () {
			socket.disconnect()
		}

	}, [])

	return (
		<>
			{selectedPlayerId &&
				<div className="center selected-player-context-menu is-visible" style={{ left: selectedPlayerId.x, top: selectedPlayerId.y }}>
					<List>
						<ListItem to={"/player/" + selectedPlayerId.ucid} middle="Voir le joueur" />
						<ListItem className="pointer" middle="Envoyer un message" onClick={(e) => openPopup(e, "private-message-popup")} />
						<ListItem className="pointer" middle="Exclure le joueur" onClick={(e) => openPopup(e, "kick-popup")} />
						<ListItem className="pointer" middle="Bannir le joueur" onClick={(e) => openPopup(e, "ban-popup")} />
					</List>
				</div>
			}

			<Popup title="Bannir le joueur" show={(openedPopup === "ban-popup")} hidePopup={() => customSetOpenedPopup(false)}>
				<div>Ban popup</div>
			</Popup>

			<Popup title="Kick le joueur" show={(openedPopup === "kick-popup")} hidePopup={() => customSetOpenedPopup(false)}>
				<div className="flex flex-column space-around flex-1">
					<div className="center">Voulez vous vraiment exclure ce joueur ?</div>
					<div>
						<Input label="Message" onChange={(val) => handleFormChange("kick-" + selectedPlayerId.id, val)} />
					</div>
					<div className="btn-group center">
						<div className="btn" onClick={(e) => kickPlayer(formsData["kick-" + selectedPlayerId.id])}>Oui</div>
						<div className="btn" onClick={(e) => customSetOpenedPopup(false)}>Non</div>
					</div>
				</div>
			</Popup>

			<Popup title="Envoyer un message au joueur" show={(openedPopup === "private-message-popup")} hidePopup={() => customSetOpenedPopup(false)}>
				<div>Message popup</div>
			</Popup>

			<div className="sidebar-container admin-dashboard-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Panneau d'administration
					</h2>

					<div className="cards-container">

						<div className="cards-container flex-1 flex-column">

							<Card title={"Joueurs connectés (" + (serverData.connectedPlayers ? Object.keys(serverData.connectedPlayers).length : "0") + "/30)"} noPadding>
								<List>
									<ListHeader left="Nom du joueur" middle="Coalition" right="" />
									{serverData.connectedPlayers && Object.keys(serverData.connectedPlayers).map(playerIdx => {
										const player = serverData.connectedPlayers[playerIdx]
										return (
											<ListItem onContextMenu={(e) => player.id === serverData.serverInfos.hostId ? null : selectPlayer(e, player)} key={player.ucid + player.name} left={player.name} middle={<CoalitionDisplay coalition={player.side} />} right={(player.id === serverData.serverInfos.hostId ? "" : <FiMoreVertical onClick={(e) => selectPlayer(e, player)} className="pointer" size={22} />)} />
										)
									})}
								</List>
							</Card>

						</div>

						<div className="cards-container flex-1 flex-column">

							<Card title="Informations du serveur" noPadding>
								<List>
									<ListItem left="Adresse IP" right={serverData.serverInfos ? serverData.serverInfos.serverIp : "N/A"} />
									<ListItem left="Port" right={serverData.serverInfos ? serverData.serverInfos.serverPort : "N/A"} />
									<ListItem left="Status" right={serverData.serverInfos ? serverData.serverInfos.serverStatus : "N/A"} />
									<ListItem left="Mission actuelle" right={serverData.serverInfos ? serverData.serverInfos.missionName : "N/A"} />
								</List>
							</Card>

							<Card title="Chat" noPadding>
								<GameChat maxHeight="400px" hostName={(serverData.serverInfos ? serverData.serverInfos.hostName : "")} />
							</Card>

						</div>

					</div>
				</div>
			</div>
		</>
	)
}

export default AdminDashboard