import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import socketIOClient from "socket.io-client"

import './Logs.css'

const Logs = (props) => {

	const [logs, setLogs] = useState([])

	useEffect(() => {

		const addLogs = (logsAdded) => {

			if(logsAdded.logType === "dcs") {
				let {newLogs} = logsAdded

				let tmpLogs = [...logs, ...newLogs]

				setLogs(tmpLogs)

			} else {
				return
			}
		}

		const socket = socketIOClient(Axios.defaults.baseURL)

		socket.on("new-server-logs", addLogs)

		return function() {
			socket.disconnect()
		}

	}, [logs])

	useEffect(() => {

		Axios.get("/api/logs/dcs").then(res => {

			setLogs(res.data.split("\n"))

		}).catch(err => {
			console.log(err)
		})

	}, [])

	return (
		<>
			<Helmet>
				<title>Logs du serveur DCS BFR</title>
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className="gallery-page page-padding">

				<div className="container">

					<h1 className="section-title center"><div className="center title">Logs</div></h1>

					<pre className="logs-container">

						{logs.map((log, idx) => {

							if(!log)
								return null

							return (
								<div key={idx}>{log}</div>
							)

						})}

					</pre>

				</div>

			</div>
		</>
	)
}

export default Logs