import Axios from 'axios'
import React, { useState } from "react"
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import Input from '../../../components/Input/Input'
import { useHistory } from "react-router"
import '../Tournaments.css'

const TournamentCreate = props => {

    const history = useHistory()

	const [formData, setFormData] = useState({})

	const handleFormChange = (input, value) => setFormData({ ...formData, [input]: value })

	const handleSubmit = e => {
		e.preventDefault()

		Axios.post("/api/tournaments", { name: formData["tournament-name"] }).then(res => {
			console.log(res)
            toast.success("Tournoi créé !")
            history.push("/tournaments/" + res.data.tournament._id)
		}).catch(err => {
			console.log(err)
		})
	}

	return (
		<>
			<Helmet>
				<title>Créer un tournoi</title>
				<meta name="description" content="Toutes les informations sur les tournois organisés" />
			</Helmet>

			<div className="tournaments-create-page page-padding">

				<div className="container">

					<h1 className="section-title">
						<div className="center title">Créer un tournoi</div>
					</h1>

					<form className="create-tournament-form tournament-form" onSubmit={handleSubmit}>

						<Input label="Nom du tournoi" onChange={(val) => handleFormChange("tournament-name", val)} />

						<div className="center">
							<button type="submit" className="btn">Créer</button>
						</div>

					</form>

				</div>
			</div>
		</>
	)
}
export default TournamentCreate