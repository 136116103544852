import Axios from 'axios'
import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import Input from '../../../components/Input/Input'
import Textarea from '../../../components/Textarea/Textarea'
import { toast } from 'react-toastify'

import '../Tournaments.css'

/*
    -- Reset classement actuel au start du tournoi

    -- Export :
        - pseudo
        - team
        - avion
        - presentation
        - setup

    -- Le jaune pete les yeux, mettre en jaune or foncé

    -- Faire apparaitre le nom de l'avion a coté du pseudo entre parenthèse

    -- Mode fullscreen pour le stream

    page tournaments

        Contour en vert pour tournois en attente
        En rouge pour tournois en cours
        En jaune pour tournois terminé

        Les noms en blanc

        icones en bleu

    -- Le score definit le gagnant

    Maps :
        Caucase
        Syrie
        Persian

    enlever le score 1 et score 2 dans la popup pour rentrer le score

    -- Possibilité d'annuler un score déja rentré

    Système d'onglets avec :
        - Gestion des joueurs avec numérotation + randomization (sur les joueurs non numérotés)
        -- affichage meme quand non connecté
        -- Classement
        Infos du tournoi
            Controles :
                -- Demarrer / stopper le tournoi
                -- Ouvrir / fermer les inscriptions
            -- Nom
            -- Description
            -- Video

    -- possibilité de mettre les matches en cours

    - New design             https://dribbble.com/shots/13820939/attachments/5427710?mode=media


*/

const TournamentRegister = props => {

    const [canRegister, setCanRegister] = useState(false)
    const [tournament, setTournament] = useState()

    const { tournamentId } = useParams()

    const history = useHistory()

    const [formData, setFormData] = useState({})

    const handleFormChange = (input, value) => setFormData({ ...formData, [input]: value })

    const handleMapCheckChange = (mapName, checked) => {
        setFormData(oldFormData => {

            const newMapsList = [...oldFormData.mapsList ?? []]

            if (newMapsList.includes(mapName) && !checked) {

                let mapIdx = newMapsList.indexOf(mapName)

                if (mapIdx !== -1)
                    newMapsList.splice(mapIdx, 1)

            } else if (!newMapsList.includes(mapName) && checked) {
                newMapsList.push(mapName)
            }

            return {
                ...oldFormData,
                mapsList: newMapsList
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        let player = {
            username: formData["username"],
            team: formData["team"],
            plane: formData["plane"],
            presentation: formData["presentation"],
            setup: formData["setup"],
            remark: formData["remark"],
            statsPage: formData["stats-page"],
            maps: formData["mapsList"] ?? []
        }

        if (!player.username)
            return toast.error('Le champ "pseudo" est obligatoire')

        if (!player.plane)
            return toast.error('Le champ "avion utilisé" est obligatoire')

        if (!player.presentation)
            return toast.error('Le champ "presentation" est obligatoire')

        if (!player.setup)
            return toast.error('Le champ "setup" est obligatoire')

        Axios.post("/api/tournaments/" + tournamentId + "/register", {
            player
        }).then(res => {
            toast.success("Votre inscription à bien été enregistrée.")
            history.push("/tournaments/" + tournamentId)
        }).catch(err => console.log(err))

        /*
        let i = 0

        const addPlayer = async () => {
            return Axios.post("/api/tournaments/" + tournamentId + "/register", {
                player: {
                    username: formData["username"] + i,
                    team: formData["team"] + i,
                    plane: formData["plane"] + i,
                    presentation: formData["presentation"] + i,
                    setup: formData["setup"] + i,
                    statsPage: formData["stats-page"] + i
                }
            }).then(res => {
                i++
                toast.success("Joueur enregistré")
                if(i <= 20)
                    addPlayer()
            }).catch(err => console.log(err))
        }

        addPlayer()*/
    }

    useEffect(() => {

        Axios.get("/api/tournaments/" + tournamentId).then(res => {

            if (res.data.registrationOpen) {
                setCanRegister(true)
            } else {
                toast.error("Les inscriptions pour ce tournoi ne sont pas ouvertes")
            }

            setTournament(res.data)

        }).catch(err => console.log(err))

    }, [tournamentId])

    return (
        <>
            <Helmet>
                <title>Inscription au tournoi BFR</title>
                <meta name="description" content="Toutes les informations sur un tournoi" />
            </Helmet>

            <div className="tournament-register-page page-padding">

                <div className="container">

                    <h1 className="section-title">
                        <div className="center title">Inscription au tournoi {tournament && tournament.name}</div>
                    </h1>

                    {canRegister &&
                        <form className="register-form tournament-form" onSubmit={handleSubmit}>

                            <Input label="Pseudo" onChange={(val) => handleFormChange("username", val)} />

                            <Input label="Team (facultatif)" onChange={(val) => handleFormChange("team", val)} />

                            <Input label="Avion utilisé" onChange={(val) => handleFormChange("plane", val)} />

                            <Textarea label="Présentation" onChange={(val) => handleFormChange("presentation", val)} />

                            <Textarea label="Setup" onChange={(val) => handleFormChange("setup", val)} />

                            <Input label="Remarques (munitions spécifique, etc ...) (facultatif)" onChange={(val) => handleFormChange("remark", val)} />

                            <Input help="Le lien de votre page de stats sur le site BFR" label="Page de stats (facultatif)" onChange={(val) => handleFormChange("stats-page", val)} />

                            <div className="maps-list-container">
                                <div>Je possède les maps : </div>

                                <div className="maps-list">

                                    {["Caucase", "Syrie", "Persian"].map((map, idx) => {
                                        return <div>
                                            <input
                                                checked={!!formData.mapsList?.includes(map)}
                                                type="checkbox"
                                                name={map}
                                                id={map}
                                                onChange={e => handleMapCheckChange(map, e.target.checked)}
                                            />
                                            <label for={map}>{map}</label>
                                        </div>
                                    })}

                                </div>
                            </div>

                            <div className="center">
                                <button type="submit" className="btn">S'inscrire</button>
                            </div>

                        </form>
                    }

                </div>

            </div>
        </>
    )
}
export default TournamentRegister