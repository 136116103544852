import React, { useEffect, useState } from "react"
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'
import Card from '../../../components/Card/Card'
import List from '../../../components/List/List'
import ListItem from '../../../components/ListItem/ListItem'
import axios from 'axios'

// Todo : verify download of missions
const AdminMissions = props => {

	const [activesMissionsList, setActivesMissionsList] = useState({})
	const [missionsList, setMissionsList] = useState({})

	const playMission = missionPath => {
		// Todo
		console.log("Play mission ", missionPath)
	}

	const addMission = missionPath => {
		// Todo
		console.log("Add mission", missionPath)
	}

	useEffect(() => {
		axios.get("/api/missions/actives").then(res => {

			let missionsPath = res.data.missionList
			let missions = {}

			if(missionsPath) {

				for (let i = 0; i < missionsPath.length; i++) {
					const missionPath = missionsPath[i]
					const missionName = missionPath.replace(/^.*[\\/]/, '')

					missions[missionName] = {
						filePath: missionPath,
						current: (i + 1 === res.data.current) ? true : false
					}
				}
			}

			setActivesMissionsList(missions)
		}).catch(err => {
			console.log(err)
		})

		axios.get("/api/missions/").then(res => {

			let missions = {}

			if(res.data.missions) {

				for (let i = 0; i < res.data.missions.length; i++) {
					const missionPath = res.data.missions[i]
					const missionName = missionPath.replace(/^.*[\\/]/, '')

					missions[missionName] = {
						filePath: missionPath
					}
				}

			}

			setMissionsList(missions)
		}).catch(err => {
			console.log(err)
		})
	}, [])

	return (
		<>
			<div className="sidebar-container admin-dashboard-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Missions
					</h2>

					<Card title="Mission Actuelles" noPadding>
						<List>
							{activesMissionsList &&
								Object.keys(activesMissionsList).map(missionKey => {
									const mission = activesMissionsList[missionKey]
									return (
										<ListItem key={missionKey} left={<a href={"/api/missions/" + encodeURI(missionKey)}>{missionKey}</a>} middle={mission.current ? <div>En cours</div> : <div className="pointer btn btn-small btn-success" onClick={(e) => playMission(mission.filePath)}>Jouer</div>} right={<div className="btn btn-danger btn-small">Supprimer</div>} />
									)
								})
							}
						</List>
					</Card>

					<div className="cards-container">

						<div className="cards-container flex-1 flex-column">

							<Card title="Missions disponibles" noPadding>
								<List>
									{missionsList && Object.keys(missionsList).map(missionKey => {
										const mission = missionsList[missionKey]
										return (
											<ListItem key={missionKey} left={<a href={"/api/missions/" + encodeURI(missionKey)}>{missionKey}</a>} right={activesMissionsList[missionKey] ? "Ajoutée" : <div className="btn btn-small" onClick={(e) => addMission(mission.filePath)}>Ajouter</div>} />
										)
									})}
								</List>
							</Card>

						</div>

					</div>

				</div>

			</div>
		</>
	)
}
export default AdminMissions