import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { IoIosArrowBack } from 'react-icons/io'
import Card from '../../components/Card/Card'
import axios from "axios"

import './PlayerPlanes.css'
import { Helmet } from 'react-helmet'

function PlayerPlanes(props) {

	const history = useHistory()

	const { playerId, planeId } = useParams()

	const [planeData, setPlaneData] = useState({})
	const [player, setPlayer] = useState("")

	const goBack = () => {
		history.goBack()
	}

	const formatTimestampDate = timestamp => {
		let intTimestamp = Math.floor(timestamp)

		let hours = (intTimestamp - (intTimestamp % 3600)) / 3600
		intTimestamp -= hours * 3600
		if(hours < 10)
			hours = "0" + hours

		let minutes = (intTimestamp - (intTimestamp % 60)) / 60
		intTimestamp -= minutes * 60
		if(minutes < 10)
			minutes = "0" + minutes

		let seconds = intTimestamp
		if(seconds < 10)
			seconds = "0" + seconds

		return hours + ":" + minutes + ":" + seconds
	}

	useEffect(() => {
		axios.get("/api/players/" + playerId).then(res => {

			let { player } = res.data

			console.log("player ", player)

			let plane = player.planes[planeId]

			plane.time_played = formatTimestampDate(plane.total)
			plane.time_flyed = formatTimestampDate(plane.inAir)

			plane.killsNumber = 0

			for (const weaponIdx in plane.weapons) {
				if (plane.weapons.hasOwnProperty(weaponIdx)) {
					const weapon = plane.weapons[weaponIdx]
					plane.killsNumber += weapon.kills ?? 0
					plane.killsNumber += weapon.groundKills ?? 0
				}
			}

			setPlaneData(plane)
			setPlayer(player)

		}).catch(e => {
			console.log(e)
		})
	}, [planeId, playerId])

	return (
		<>
			<Helmet>
				<title>{player.name ? "Statistiques de " + player.name + " avec " + planeData.planeName + " | BFR" : "Tir alliés sur BFR"}</title>
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className="player-planes-page background-grey container page-padding">
				<h1 className="section-title">
					<div onClick={goBack} className="pointer pre-icon"><IoIosArrowBack /></div><div className="center title">Détail du {planeData.planeName ?? planeId}</div><div className="post-icon">&nbsp;</div>
				</h1>

				<Card title={planeData.planeName ?? planeId} className="plane-stats">

					<div className="plane-stats-inline">

						<div className="stat-box">
							<div className="stat-number">{planeData.time_played ?? 0}</div>
							<div className="stat-label">Temps Joué</div>
						</div>

						<div className="stat-box">
							<div className="stat-number">{planeData.time_flyed ?? 0}</div>
							<div className="stat-label">Temps en vol</div>
						</div>

						<div className="stat-box">
							<div className="stat-number">{planeData.killsNumber ?? 0}</div>
							<div className="stat-label">Éliminations</div>
						</div>

						<div className="stat-box">
							<div className="stat-number">{planeData.losses ? planeData.losses.crash : 0}</div>
							<div className="stat-label">Perdus</div>
						</div>

					</div>

				</Card>

				<h1 className="section-title">
					Types d'armement
				</h1>

				{Object.keys(planeData.weapons ?? {}).length === 0 &&
					<div>Aucune munition n'a été tirée par cet avion</div>
				}

				{Object.keys(planeData.weapons ?? {}).map(idx => (
					<Card key={"plane-" + idx} title={idx} className="plane-stats weapon-stats">

						<div className="plane-stats-inline">

							<div className="stat-box">
								<div className="stat-number">{planeData.weapons[idx].kills > 0 ? planeData.weapons[idx].kills : planeData.weapons[idx].groundKills ?? 0}</div>
								<div className="stat-label">Éliminations</div>
							</div>

							<div className="stat-box">
								<div className="stat-number">{planeData.weapons[idx].shot ?? 0}</div>
								<div className="stat-label">Tirés</div>
							</div>

							<div className="stat-box">
								<div className="stat-number">{planeData.weapons[idx].hits ?? 0}</div>
								<div className="stat-label">Touchés</div>
							</div>

						</div>

					</Card>
				))}

			</div>

		</>
	)
}

export default PlayerPlanes