import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"

import './PlayerTeamKills.css'
import { Helmet } from 'react-helmet'

function PlayerTeamKills(props) {

	const history = useHistory()
	const { playerId } = useParams()
	const [player, setPlayer] = useState(null)

	const goBack = () => {
		history.goBack()
	}

	const formatStrDate = strDate => {
		return new Intl.DateTimeFormat("fr-FR", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			formatMatcher: "basic"
		}).format(new Date(strDate))
	}

	useEffect(() => {
		axios.get("/api/players/" + playerId).then(res => {

			let { player } = res.data

			setPlayer(player)

		}).catch(e => {
			console.log(e)
		})
	}, [playerId])

	return (
		<>
			<Helmet>
				<title>{(player && player.name) ? "Tir alliés de " + player.name + " sur BFR" : "Tir alliés sur BFR"}</title>
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className="player-team-kills-page background-grey container page-padding">

				<h1 className="section-title">
					<div onClick={goBack} className="pointer pre-icon"><IoIosArrowBack /></div><div className="center title">Liste des alliés tués</div><div className="post-icon">&nbsp;</div>
				</h1>

				<table className="table">
					<thead>
						<tr>
							<th>I</th>
							<th>From</th>
							<th>To Plane</th>
							<th>To Player</th>
							<th>Date</th>
							<th>Arme</th>
							<th>Pardonné</th>
						</tr>
					</thead>

					<tbody>
						{player && player.teamKills.map((teamKill, idx) => {
							return (
								<tr key={teamKill._id}>
									<td className="small">{idx + 1}</td>
									<td>{teamKill.fromPlane}</td>
									<td>{teamKill.toPlane}</td>
									<td>{teamKill.targetIsAi ? "IA" : teamKill.toPlayer.name}</td>
									<td>{formatStrDate(teamKill.createdAt)}</td>
									<td>{teamKill.weapon}</td>
									<td>{teamKill.forgiven ? "Oui" : "Non"}</td>
								</tr>
							)
						})}
					</tbody>
				</table>

			</div>
		</>
	)
}

export default PlayerTeamKills