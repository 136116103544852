import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'
import socketIOClient from "socket.io-client"

import './ServerStatus.css'

function ServerStatus() {

	const [serverStatus, setServerStatus] = useState("waiting")

	const updateStatus = () => {
		axios.get("/api/server/status").then(res => {
			setServerStatus(res.data.status)
		}).catch(err => {
			setServerStatus("waiting")
			console.log(err)
		})
	}

	useEffect(() => {

		updateStatus()

		const socket = socketIOClient(axios.defaults.baseURL)

		socket.on("server-status-change", updateStatus)

		return function() {
			socket.disconnect()
		}

	}, [])

	let returnHtml = <div></div>

	switch (serverStatus) {
		case "waiting":
			returnHtml = <Link to="/status" className={"server-status status-waiting"}>
			<div className="status-label">Serveur :</div>
			<div className="status-value">En Attente</div>
		</Link>
			break;
		case "online":
			returnHtml = <Link to="/status" className={"server-status status-online"}>
			<div className="status-label">Serveur :</div>
			<div className="status-value">En Ligne</div>
		</Link>
			break;
		case "offline":
			returnHtml = <Link to="/status" className={"server-status status-offline"}>
			<div className="status-label">Serveur :</div>
			<div className="status-value">Hors Ligne</div>
		</Link>
			break;
		default:
			returnHtml = <div></div>
			break;
	}

	return returnHtml
}

export default ServerStatus