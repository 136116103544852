import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import '../Tournaments.css'

import { BsClock, BsCheck } from 'react-icons/bs'
import { FaFighterJet } from "react-icons/fa"

import AuthService from "../../../services/AuthService"
import Axios from 'axios'

const TournamentsList = props => {

    const [user, setUser] = useState({})
    const [tournaments, setTournaments] = useState({})

    useEffect(() => {

        setUser(AuthService.getCurrentUser())

        Axios.get("/api/tournaments").then(res => {

            let tournaments = res.data

            let tournamentsByYear = {}

            for (let i = 0; i < tournaments.length; i++) {
                const tournament = tournaments[i]

                // Get the year of the tournament
                let year = new Date(tournament.createdAt).getFullYear()

                // If the year is not in the object, create it
                if (!tournamentsByYear[year]) {
                    tournamentsByYear[year] = []
                }

                tournamentsByYear[year].push(tournaments[i])
            }

            Object.keys(tournamentsByYear).forEach(year => {

                tournamentsByYear[year] = tournamentsByYear[year].sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt)
                })
            })

            setTournaments(tournamentsByYear)
        }).catch(err => console.log(err))

    }, [])

    return (
        <>
            <Helmet>
                <title>Tournois BFR</title>
                <meta name="description" content="Toutes les informations sur les tournois organisés" />
            </Helmet>

            <div className="tournaments-list-page page-padding">

                <div className="container">

                    <h1 className="section-title">
                        <div className="center title">Tournois</div>
                    </h1>

                    {user && user.admin &&
                        <Link to="/tournaments/new" className="btn">Ajouter un tournoi</Link>
                    }

                    <div>
                        {Object.keys(tournaments).sort((a, b) => b - a).map(year => {

                            const _tournaments = tournaments[year]

                            return <div key={year}>
                                <h2 className="year">{year}</h2>

                                <div className="tournaments-list">
                                    {_tournaments.map(tournament => {

                                        const baseLink = "/tournaments/" + tournament._id

                                        const registerLink = baseLink + "/register"

                                        const icon = tournament.status === "ended"
                                            ? <BsCheck size={24} />
                                            : tournament.status === "started"
                                                ? <FaFighterJet size={20} />
                                                : <BsClock size={20} />

                                        return <Link
                                            to={baseLink}
                                            key={tournament._id}
                                            className={"tournament tournament-" + tournament.status}
                                        >
                                            <div className="tournament-icon">{icon}</div>
                                            <div className="tournament-name">{tournament.name}</div>
                                            <div className="tournament-controls">
                                                {tournament.registrationOpen && <Link to={registerLink}>S'inscire</Link>}
                                            </div>
                                        </Link>
                                    })}

                                </div>

                            </div>

                        })}
                    </div>

                </div>

            </div>

        </>
    )
}

export default TournamentsList
