import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './AdminSidebar.css'

function AdminSidebar(props) {

	const [selectedMenu, setSelectedMenu] = useState(null)

	const toggleMenu = menuName => {
		if(selectedMenu === menuName)
			setSelectedMenu(null)
		else
			setSelectedMenu(menuName)
	}

	const handleSubMenuClick = () => {
		setSelectedMenu(null)
	}

	return (
		<div className={"sidebar " + props.className}>
			<div className="sidebar-title">
				Administration
			</div>

			<div className="sidebar-links">
				<Link to="/admin/dashboard">Panneau général</Link>
				<Link to="/admin/chat">Messages</Link>
				<Link to="/admin/missions">Missions</Link>
				<Link to="/admin/bans">Bannissements</Link>
				<Link to="/admin/players">Joueurs</Link>
				<Link to="/admin/gallery">Galerie</Link>
				<div className="sub-menu">
					<div className="sub-menu-title" onClick={(e) => toggleMenu("logs")}>Logs</div>
					<div className="sub-menu-content">
						{selectedMenu === "logs" &&
							<>
								<Link to="/admin/logs/dcs" onClick={handleSubMenuClick} className="sub-menu-item">DCS</Link>
								<Link to="/admin/logs/lotatc" onClick={handleSubMenuClick} className="sub-menu-item">LotAtc</Link>
								<Link to="/admin/logs/tacview" onClick={handleSubMenuClick} className="sub-menu-item">Tacview</Link>
								<Link to="/admin/logs/BFRScript" onClick={handleSubMenuClick} className="sub-menu-item">Script BFR</Link>
							</>
						}
					</div>
				</div>
				<div className="sub-menu">
					<div className="sub-menu-title" onClick={(e) => toggleMenu("settings")}>Paramètres</div>
					<div className="sub-menu-content">
						{selectedMenu === "settings" &&
							<>
								<Link to="/admin/settings/dcs" onClick={handleSubMenuClick} className="sub-menu-item">DCS</Link>
								<Link to="/admin/settings/lotatc" onClick={handleSubMenuClick} className="sub-menu-item">LotAtc</Link>
								<Link to="/admin/settings/tacview" onClick={handleSubMenuClick} className="sub-menu-item">Tacview</Link>
							</>
						}
					</div>
				</div>
				<Link to="/admin/restarts">Redémarrages</Link>
				<Link to="/admin/srs">SRS</Link>
				<Link to="/admin/map">Carte</Link>
			</div>
		</div>
	)
}

export default AdminSidebar