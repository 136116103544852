import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Link } from 'react-router-dom'

import './SearchBar.css'

function SearchBar(props) {

	const [search, setSearch] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [recentResults, setRecentsResults] = useState([])
	const [isFocused, setIsFocused] = useState(false)

	const handleChange = (e) => {
		let {value} = e.target

		setSearch(value)

		if(value.length < 3)
			return setSearchResults([])

		axios.get("/api/players/search/" + value).then(res => {
			setSearchResults(res.data.players)
		}).catch(err => {
			console.log(err)
		})
	}

	const handleClick = (e, oldPlayer) => {

		let recent = localStorage.getItem("recentSearch")

		if(!recent)
			recent = []
		else
			recent = JSON.parse(recent)

		for (let i = 0; i < recent.length; i++) {
			if(oldPlayer.uniqid === recent[i].uniqid) {
				recent.splice(i, 1)
				break
			}
		}

		let player = {
			uniqid: oldPlayer.uniqid,
			name: oldPlayer.name,
			ucid: oldPlayer.ucid
		}

		if(recent.length > 4) {
			recent.pop()
		}

		let toSet = JSON.stringify([player, ...recent])

		localStorage.setItem("recentSearch", toSet)
	}

	useEffect(() => {
		let recent = localStorage.getItem("recentSearch")

		if(recent)
			setRecentsResults(JSON.parse(recent))
	}, [])

	return (
		<div className="search-bar">
			<div className="search-bar-form">
				<input type="text" placeholder="Rechercher un joueur" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} value={search} onChange={handleChange}/>
			</div>
			<div className="search-bar-suggestions" onMouseDown={(e) => e.preventDefault()}>
				{search.length === 0 && isFocused && recentResults.map(player => (
					<Link to={"/player/" + player.uniqid} key={player.ucid} onClick={(e) => handleClick(e, player)}>{player.name}</Link>
				))}
				{searchResults.map(player => (
					<Link to={"/player/" + player.uniqid} key={player.ucid} onClick={(e) => handleClick(e, player)}>{player.name}</Link>
				))}
			</div>
		</div>
	)
}

export default SearchBar