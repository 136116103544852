import React, { useEffect, useState } from 'react'
import socketIOClient from "socket.io-client"
import axios from 'axios'

import './Status.css'
import { Helmet } from 'react-helmet'

const Status = props => {

	const [serverStatus, setServerStatus] = useState("waiting")

	const updateStatus = () => {
		axios.get("/api/server/status").then(res => {
			setServerStatus(res.data.status)
		}).catch(err => {
			setServerStatus("waiting")
			console.log(err)
		})
	}

	useEffect(() => {

		updateStatus()

		const socket = socketIOClient(axios.defaults.baseURL)

		socket.on("server-status-change", updateStatus)

		return function() {
			socket.disconnect()
		}

	}, [])

	return (
		<>
			<Helmet>
				<title>Status du serveur BFR</title>
				<meta name="description" content="Toutes les informations sur les pannes concernant le serveur DCS BFR" />
			</Helmet>

			<div className="status-page page-padding">

				<div className="content">

					{serverStatus === "online" &&
						<div className="status-container online">
							<div className="status-title">Le serveur est en ligne !</div>
						</div>
					}

					{serverStatus === "offline" &&
						<div className="status-container offline">
							<div className="status-title">Le serveur est hors ligne !</div>
						</div>
					}

					{serverStatus === "waiting" &&
						<div className="status-container waiting">
							<div className="status-title">En attente ...</div>
						</div>
					}

				</div>

				<div className="content center">
					Le serveur redémarre tous les jours à : 10h, 15h, 21h et 2h
				</div>

			</div>
		</>
	)
}

export default Status