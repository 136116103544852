import Axios from 'axios'
import React, { useEffect, useState } from "react"
import Card from '../../components/Card/Card'
import List from '../../components/List/List'
import ListItem from '../../components/ListItem/ListItem'
import ServerStatus from '../../components/ServerStatus/ServerStatus'
import { Helmet } from "react-helmet"

import './ServerInfos.css'
/*
- Serveur BFR
- Server Status
- Players online
- Lotatc online
- Graphique fréquentations

- Phraséologie (Plus lien LotAtc)

- SRS (Tutos)
*/

const ServerInfos = props => {

    const [serverData, setServerData] = useState({})

    useEffect(() => {

        Axios.get('/api/server/basic-infos').then(res => {

            let server = res.data

            server.hoursPlayed = Math.round(Math.floor(server.timePlayed) / 3600)

            setServerData(server)

        }).catch(err => {
            console.log(err)
        })

    }, [])

    return (
        <>
            <Helmet>
                <title>Informations serveur BFR</title>
            </Helmet>

            <div className="page-padding infos-server-page">

                <h1 className="section-title center"><div className="title">Informations Pratiques</div></h1>

                <section id="bfr-server">
                    <div className="infos-server-title container">Serveur DCS</div>

                    <div className="infos-server-content container">

                        <div className="infos-server-1">
                            <div className="top">
                                <ServerStatus />
                                <div>Il y a actuellement {serverData?.players?.length ?? 0} joueurs en ligne</div>
                            </div>
                            <div>

                            </div>
                            <div>

                                <Card noPadding title="Liste des ATC">
                                    <List>
                                        {serverData.lotAtc &&
                                            <>
                                                {serverData.lotAtc.atc.map((player, idx) => {
                                                    return (
                                                        <ListItem key={"atc-" + player} left={player} right="ATC" />
                                                    )
                                                })}
                                                {serverData.lotAtc.awacs.map((player, idx) => {
                                                    return (
                                                        <ListItem key={"awacs-" + player} left={player} right="AWACS" />
                                                    )
                                                })}
                                                {serverData.lotAtc.jtac.map((player, idx) => {
                                                    return (
                                                        <ListItem key={"jtac-" + player} left={player} right="JTAC" />
                                                    )
                                                })}
                                            </>
                                        }
                                        {serverData.lotAtc && serverData.lotAtc.atc.length === 0 && serverData.lotAtc.awacs.length === 0 && serverData.lotAtc.jtac.length === 0 &&
                                            <ListItem left={"Aucun ATC connecté"} />
                                        }
                                    </List>
                                </Card>

                            </div>
                        </div>

                        {/* <div className="frequentations-graph">
							Voici un graphique de la fréquentation du serveur

							<div className="iframe-container">
								<iframe src="https://dcsstats.lolnet.org/single_graph/BFR_online_players" title="Graphique de la fréquentation du serveur DCS BFR" frameBorder="0" scrolling="no" width="1300px" height="500px"></iframe>
								<div className="author">Graphique par Quatre</div>
							</div>

						</div> */}

                    </div>
                </section>

                <section id="srs">
                    <div className="infos-server-title container">SRS</div>

                    <div className="infos-server-content container">

                        <a href="http://dcssimpleradio.com/" target="_blank" rel="nofollow noreferrer">SRS (Simple Radio Standalone)</a> est un outil de communication intra game qui permet de simuler l'utilisation des communications
                        des radios de vos avions et de l'échange entre les pilotes.<br />
                        <br />
                        <strong>Il est vivement recommandé pour une meilleure immersion sur notre serveur.</strong><br />
                        <br />
                        Assez simple d'utilisation, car si vous l'avez téléchargé, lors de votre connexion au serveur,
                        il vous demandera directement de vous connecter sur notre serveur SRS. Si pour une quelconque
                        raison vous aviez besoin de l'adresse pour vous connecter, vous pourrez trouver celle-ci dans le
                        briefing de la mission en cours.<br />
                        <br />
                        Pour une utilisation de base, vous pouvez mettre les contrôles :<br />
                        <br />
                        - Push To Talk - PTT<br />
                        - Next radio<br />
                        - Previous Radio<br />
                        <br />
                        Attention, nous avons déjà eu plusieurs fois le cas !!!<br />
                        Si vous sélectionnez un canal sur SRS avec l'étoile "comm 1" pour F18c, et que vous voulez
                        contacter l'ATC IA avec le raccourci DCS "menu de communication" cela ne fonctionnera pas,
                        faites toujours attention que la touche en question soit assignée sur chaque module, il y a plusieurs touches pour les radios
                        comme "interr. comm 1" par exemple pour le F18c pour communiquer avec les fréquences IA.<br />
                        <br />
                        Le raccourci "GCtrl + GShift + Esc" vous permet de changer le design voir de cacher la fenêtre DCS.
                    </div>
                </section>

                <section id="phraseology">
                    <div className="infos-server-title container">Phraséologie</div>

                    <div className="infos-server-content container">

                        <a href={Axios.defaults.baseURL + "/api/download/Phraseologie_-_Les_bases.pdf"} rel="noreferrer nofollow" target="_blank" download="Phraseologie - Les bases.pdf" className="btn btn-success btn-small download-file">Télécharger</a>

                        <div className="phraseology-title">1. Généralités</div>

                        <ul>
                            <li>Ce document est à destination des <strong>joueurs novices ou de niveau intermédiaire</strong> mais souhaitant parfaire leurs connaissances dans le domaine de la phraséologie de l’aviation militaire, adaptée à DCS World.</li>
                            <li>Pour bien débuter dans les communications sur SRS :
                                <ul>
                                    <li><strong>oser parler</strong> (même si la phraséologie n’est pas rigoureuse ou exacte) ;</li>
                                    <li><strong>rester concis</strong> (ne pas monopoliser les canaux de communication);</li>
                                    <li><strong>attendre la réponse</strong> de l’interlocuteur lors du premier appel ;</li>
                                    <li><strong>appelé/appelant</strong> (lors d’un appel initial, commencer par le callsign de l’appelé, puis le callsign de l’appelant).</li>
                                </ul>
                            </li>
                            <li><strong>Propositions et directives</strong> : le dialogue entre le pilote et le contrôleur aérien est un échange, voire une négociation. Le contrôleur peut donner des directives, peut proposer une alternative, ou imposer une action, mais le pilote reste le décideur ultime quant à la sécurité immédiate de son appareil ou de sa patrouille.</li>
                            <li><strong>Vérification des informations/directives</strong> :en raison de la qualité parfois dégradée des communications radio, et de la charge mentale de travail des pilotes et contrôleurs, des erreurs de compréhension peuvent exister durant des échanges. Pour cela, il est impératif de collationner, c’est-à-dire répéter, les mots clés. Par exemple :
                                <ul>
                                    <li>AWACS: <i>« Béchamel 1-1, Overlord, prenez cap010, flight level300, pour commit sur une patrouille de 2Su-27 décollant de Beslan. »</i></li>
                                    <li>CP (Chef de Patrouille) : <i>« Overlord, 1-1, 010 FL300, commit sur 2Su-27 »</i></li>
                                </ul>
                            </li>
                            <li><strong>Callsign</strong> : l’indicatif de l’appareil doit répondre à quelques critères:facilement prononçable, composé de moins de 4syllabes (idéalement 1 ou 2syllabes), suivi de 2chiffres: le premier indique le numéro de lapatrouille, le deuxième le rang dans la patrouille. Ex: Cognac 1-1 signifie, en plus d’un amateur d’alcool au goût sûr, que l’appareil fait partie de la patrouille n°1, et qu’il est CP.</li>
                            <li><strong>Communications du CP</strong> : seul le Chef de Patrouille communique avec lecontrôleur aérien (ATC ou AWACS), sauf si la patrouille se scinde en deux, auquel cas le Second de Patrouille assure la communication avec le contrôle pour le ou les ailiers sous sa responsabilité.</li>
                        </ul>

                        <div className="phraseology-title">2. Phase de décollage : COMM avec l'ATC</div>

                        <ul>
                            <li><strong>L’ATC (Air Traffic Control) est en charge de la gestion des aéronefs dans sa zone de contrôle</strong>; pour DCS, nous partirons sur la simplification suivante: l’ATC contrôle les appareils sur quelques dizaines de nautiques autour de sa base aérienne ou de son aéroport, regroupant en une seule fréquence le Sol, la Tour et l’Approche.</li>

                            <li>Dès que la <strong>procédure de démarrage de l’appareil</strong> (rampstart) permet d’allumer la radio UHF/VHF, le pilote contactera l’ATC:
                                <ul>
                                    <li>CP (Chef de Patrouille) : <i>«Kutaisi, Béchamel 1-1, radio check. »</i></li>
                                    <li>ATC : <i>« Lima Charlie. »</i> ou <i>« Loud and clear. »</i> ou <i>« 5/5. »</i> (par exemple)</li>
                                    <li>CP : <i>« Demandons mise en route. »</i></li>
                                    <li>ATC : <i>« Mise en route approuvée, Béchamel 1-1, indiquez mission. »</i></li>
                                    <li>CP : <i>« Mission CAP (ou Strike, ou SEAD, ou CAS...), patrouille de 2F/A-18, à destination de la BARCAP Nord (ou Front Est, ou Gori, etc.). »</i></li>
                                </ul>
                            </li>

                            <li>Dès que la <strong>patrouille est prête à rouler</strong> :
                                <ul>
                                    <li>CP : <i>« Kutaisi, Béchamel 1-1, 2F/A-18, demandons roulage pour décollage. »</i></li>
                                    <li>ATC : <i>« Béchamel 1-1, KutaisiSol, piste en service 26, couleur terrain bleue, FE2984, roulez point d’attente Delta. »</i> (terrain bleu signifie une visibilité d’au moins 4,3nm et un plafond d’au moins 2500ft)</li>
                                    <li>CP : <i>« Roulage piste 26. »</i></li>
                                </ul>
                            </li>

                            <li><strong>Durant le roulage</strong>, l’ATC peut contacter la patrouille pour organiser la sortie de zone après décollage :
                                <ul>
                                    <li>ATC : « Béchamel 1-1, Kutaisi Tour,rappelez prêt à copier la clearance de départ. »</li>
                                    <li>CP : « Béchamel 1-1, prêt à copier. »</li>
                                    <li>ATC : « Après décollage montez niveau 200, tournez à droite cap 020, rappelez en sortie de zone. »</li>
                                    <li>CP : « FL 200, cap 020 »</li>
                                </ul>
                            </li>

                            <li><strong>Au point d’attente</strong> :
                                <ul>
                                    <li>CP : « Kutaisi Tour, Béchamel 1-1, départ trail 10s (ou départ patrouille serrée), demandons alignement. »</li>
                                    <li>ATC : « Béchamel 1-1, alignement piste 26, autorisé décollage, vent du 270 10kts, rappelez en sortie de zone. »</li>
                                    <li>CP : « Alignement piste 26, autorisé décollage. »</li>
                                </ul>
                            </li>

                            <li><strong>En sortie de zone</strong> :
                                <ul>
                                    <li>CP : « Kutaisi Contrôle, Béchamel 1-1, FL 200, cap 020, pour changement de fréquence. »</li>
                                    <li>ATC : « Béchamel 1-1, passez avec Overlord sur 271.0, au revoir et bon vol. »</li>
                                </ul>
                            </li>

                        </ul>

                        <div className="phraseology-title">3. Phase admin et tactique : COMM avec l'AWACS</div>

                        <ul>
                            <li><strong>L'AWACS (Airbone Warning and Control System) est en charge de la surveillance, du commandement et du guidage des patrouilles dans son volume de contrôle</strong>; s’il n’y a qu’un seul contrôleur AWACS, il pourra gérer les missionsair-air (BARCAP, TARCAP, Sweep...) et/ou les missions air-sol (SEAD, DEAD, Strike...).</li>

                            <li><strong>Prise de contact</strong> :
                                <ul>
                                    <li>CP : « Overlord, Béchamel 1-1, bonjour. »</li>
                                    <li>AWACS : « Béchamel 1-1,bonjour, identifié radar, indiquez mission. »</li>
                                    <li>CP : « Mission CAP (par exemple), 2 F/A-18, à destination du front Nord. » (si l’AWACS n’indique pas « identifié radar », le CP précisera sa provenance et sa position)</li>
                                </ul>
                            </li>

                            <li><strong>Mission air-air</strong> :
                                <ul>
                                    <li>AWACS : « Béchamel 1-1, Overlord. »</li>
                                    <li>CP : « Overlord parlez. »</li>
                                    <li>AWACS : « Pour interception patrouille de 2 Su-27 BRAA 270 pour 60 10000ft hot. » (BRAA pour Bearing Range Altitude Attitude)</li>
                                    <li>CP : « 2 Su-27 au 270 60nm. »</li>
                                    <li>AWACS : « Pour prendre flanking les bandits, prenez cap 320, montez niveau 300 et passez buster. »(« buster » est la vitesse à la puissance militaire, tandis que « gate » est la vitesse maximale avec PC).</li>
                                    <li>CP : « Cap 320 FL 300 buster. »</li>
                                    <li>AWACS : « Indiquez status carburant et ordonnance. »</li>
                                    <li>CP : « 2000 livres et 4/1/2+. » (signifie que l’appareil de la patrouille disposant de la plus petite quantité de carburant a 2000lbs, et que la somme de l’armement air-air de la patrouille est de 4 Fox3, 1 Fox1, 2 Fox2, et des munitions suffisantes pour le canon)</li>
                                    <li>...</li>
                                </ul>
                            </li>

                            <li><strong>Mission air-sol</strong> :
                                <ul>
                                    <li>AWACS : « Béchamel 1-1,Overlord. »</li>
                                    <li>CP : « Overlord parlez. »</li>
                                    <li>AWACS : « Pour élimination d’une Shilka, prêt à copier coordonnées ? » (Ce peut être aussi un guidage par relèvement + distance).</li>
                                    <li>CP : « Prêt à copier. »</li>
                                    <li>...</li>
                                </ul>
                            </li>

                            <li><strong>En sortie de zone</strong> :
                                <ul>
                                    <li>CP : « Overlord, Béchamel 1-1, à 20nm des installations de Kutaisi, pour changement de fréquence. »</li>
                                    <li>AWACS : « Béchamel 1-1, descendez 5000ft FE, et passez avec Kutaisi Contrôle sur 263.0, bon retour. »</li>
                                </ul>
                            </li>
                        </ul>

                        <div className="phraseology-title">4. Phase d'atterrissage : COMM avec l'ATC</div>

                        <ul>
                            <li><strong>En entrée de zone de contrôle de l’aéroport</strong> :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, 2 F/A-18 au Nord de vos installation, pour atterrissage au break. »</li>
                                    <li>ATC : « Béchamel 1-1, piste en service 26, couleur terrain bleu, vent du 250 8kts, FE 2984, rappelez NE. » (il est demandé au pilote de rappeler la tour lorsque la patrouille survolera le Point d’Entrée Nord-Est).</li>
                                    <li>CP : « Piste 26, FE 2984. »</li>
                                </ul>
                            </li>

                            <li><strong>Au Point d’Entrée</strong> :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, pour rejoindre Initial piste 26. »</li>
                                    <li>ATC : « Béchamel 1-1, circuit break à droite, rappelez Point Initial 26. »</li>
                                    <li>CP : « Circuit break à droite, rappelons à l’Initial 26. »</li>
                                </ul>
                            </li>

                            <li><strong>Au Point Initial</strong> (à quelques nautiques dans l’axe de la piste) :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, passant Point Initial, FE 2984. »</li>
                                    <li>ATC : « Béchamel 1-1, numéro 1 rappelez dernier virage train sorti verrouillé. »</li>
                                </ul>
                            </li>

                            <li><strong>Dernier virage</strong> (base) :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, dernier virage, train sorti verrouillé. »</li>
                                    <li>ATC : « Béchamel 1-1, autorisé atterrissage piste 26, vent du 250 8kts. »</li>
                                </ul>
                            </li>

                            <li><strong>Après l’atterrissage, sur la piste</strong> (vitesse contrôlée) :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, vitesse contrôlée. »</li>
                                    <li>ATC : « Béchamel 1-1,dégagez première intersection à gauche, rappelez piste dégagée. »</li>
                                </ul>
                            </li>

                            <li><strong>Sur le taxiway</strong> (sortie de piste) :
                                <ul>
                                    <li>CP : « Kutaisi, Béchamel 1-1, piste dégagée. »</li>
                                    <li>ATC : « Béchamel 1-1, roulez vers parking principal via taxiways gauche puis Tango Papa, coupez sans rappeler. » (TP signifie Taxyway Parallèle à la piste).</li>
                                </ul>
                            </li>
                        </ul>

                        <div className="phraseology-title">5. Acronymes et brevity words</div>

                        <ul className="phraseology-words">
                            <li><strong>BarCAP</strong> (Barrier Combat Air Patrol) : intervention en patrouille de combat aérien destinée à interdire le franchissement d’une zone délimitée.</li>
                            <li><strong>Bingo</strong> : minima carburant pour un retour base.</li>
                            <li><strong>Blind</strong> : pas de contact visuel avec la position d’un aéronef ou d’une troupeau sol alliée.</li>
                            <li><strong>Block</strong> : tranche d’altitude dans laquelle peut évoluer un aéronef allié,à sa convenance,par dizaine de milliers de pieds.</li>
                            <li><strong>Bogey</strong> : contact radar ou visuel sur un aéronef inconnu.</li>
                            <li><strong>BRAA</strong> (Bearing, Range, Altitude, Attitude) : information fournie à un appareil allié sur un autre aéronef; le cap à prendre, la distance, l’altitude en QNE, et l’attitude (hot en rapprochement, flanking droit signifiant présentant son flanc en direction de la droite de l’allié, cold en éloignement...).</li>
                            <li><strong>Bullseye</strong> : point de référence établi lors du briefing et connu des membres d’une même coalition, la position d’un point d’intérêt pouvant être indiquée depuis le Bullseye par un relèvement et une distance.</li>
                            <li><strong>Buster</strong> : vitesse à la puissance militaire, sans PC.</li>
                            <li><strong>Delta</strong> : vitesse économique.</li>
                            <li><strong>FL</strong> (ou niveau de vol) : altitude conventionnelle en centaines de piedsstandard.</li>
                            <li><strong>Fox</strong> : tir d’un missile air-air à guidage radar semi-actif (Fox1), IR (Fox2), ou radar actif (Fox3).</li>
                            <li><strong>Fox Echo</strong> (ou QFE) : hauteur par rapport au sol.</li>
                            <li><strong>Gate</strong> : vitesse maximale, avec PC.</li>
                            <li><strong>No Joy</strong> : perte du contact visuel sur l’aéronef ennemi.</li>
                            <li><strong>QNE</strong> (ou Standard) : altitude réglée par rapport au calage altimétrique sur la pression atmosphérique standard normale au niveau de la mer, soit 1013,25hPa.</li>
                            <li><strong>QNH</strong> : pression barométrique corrigée et ramenéeau niveau moyen de la mer.</li>
                            <li><strong>Picture</strong> : requête/information afin d’améliorer la Situation Awarness.</li>
                            <li><strong>RTB</strong> (Return To Base) : retour à un aéroport ou un navire.</li>
                            <li><strong>SEAD</strong> (Suppression of Enemy Air Defense) : neutralisation ou destruction des moyens de défensesanti-aériennes disposant d’un radar d’acquisition, de poursuite ou de conduite de tir.</li>
                            <li><strong>Tally</strong> : contact visuel sur une cible, un bandit un bogey, une position ou un point de repère ennemi.</li>
                            <li><strong>TarCAP</strong> (Target Combat Air Patrol) : patrouille de combat aérien à l’aplomb ou à proximité d’un vol Strike, à des fins de protection.</li>
                            <li><strong>Uniform</strong> : radio UHF/AM.</li>
                            <li><strong>Victor</strong> : radio VHF/AM.</li>
                            <li><strong>Visual</strong> : contact visuel sur un aéronef ou une troupe au sol alliée.</li>
                        </ul>

                        <div className="author">Document par Majh</div>

                    </div>
                </section>

            </div>
        </>
    )
}
export default ServerInfos