import Axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";

import './Calendar.css'

const Calendar = props => {

	const [events, setEvents] = useState([])
	const [selectedEvent, setSelectedEvent] = useState(null)
	const [calendar, setCalendar] = useState([])
	const [currentMonthStr, setCurrentMonthStr] = useState(null)

	const findAncestor = (el, sel) => {
		while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
		return el;
	}

	const removeEventPopup = () => {
		setSelectedEvent(null)
	}

	const getEventByDay = useCallback((year, month, day) => {
		return events[year + '-' + month + '-' + day] ? events[year + '-' + month + '-' + day] : []
	}, [events])

	const buildCalendar = useCallback((year, month) => {
		let currentDate = new Date()

		if(!year)
			year = currentDate.getFullYear()

		if(!month)
			month = currentDate.getMonth()

		let calendar = []

		let monthDate = new Date(year, month)

		let firstDay = monthDate.getDay() // First day (0-6) where sunday = 0

		// set monday = 0 and sunday = 6
		if(firstDay === 0)
			firstDay = 6
		else
			firstDay--

		let daysInMonth = 32 - new Date(year, month, 32).getDate()
		let daysInPrevMonth = 32 - new Date(year, month - 1, 32).getDate()

		let prevMonth = month - 1;
		let prevMonthYear = year;

		let nextMonth = month + 1;
		let nextMonthYear = year;

		if(month === 0) {
			prevMonth = 11
			prevMonthYear = year - 1
		}
		else if(month === 11) {
			nextMonth = 0
			nextMonthYear = year + 1
		}

		// Build calendar array
		for (let i = 0; i < 42; i++) {

			let weekDay = i % 7

			let dayInfos = {
				weekDay,
				month: "current"
			}

			if(i >= firstDay && i-firstDay+1 <= daysInMonth) { // Days of current month
				let dayNumber = i - firstDay + 1

				dayInfos.month = "current"
				dayInfos.dayNumber = dayNumber
				dayInfos.events = getEventByDay(year, month, dayNumber)
			}
			else if(i < firstDay) { // Days of previous month
				let dayNumber = daysInPrevMonth - (firstDay - i - 1)

				dayInfos.month = "prev"
				dayInfos.dayNumber = dayNumber
				dayInfos.events = getEventByDay(prevMonthYear, prevMonth, dayNumber)
			}
			else if(i > daysInMonth) { // Days of next month
				let dayNumber = i - (daysInMonth + firstDay - 1)

				dayInfos.month = "next"
				dayInfos.dayNumber = dayNumber
				dayInfos.events = getEventByDay(nextMonthYear, nextMonth, dayNumber)
			}

			calendar.push(dayInfos)
		}

		setCalendar(calendar)
	}, [getEventByDay])

	const handleEventPopupClick = e => {
		e.stopPropagation()
	}

	const handleEventClick = e => {
		e.stopPropagation()

		let elem = e.target

		if(!elem.classList.contains("event-item")) {
			let ancestor = findAncestor(e.target, '.event-item')

			if(ancestor)
				elem = ancestor
			else {
				console.log("No ancestors found")
				return false
			}
		}

		let eventId = elem.getAttribute("data-event-id")

		if(!eventId ||eventId === selectedEvent?.id)
			setSelectedEvent(null)
		else {
			setSelectedEvent({
				id: eventId,
				ref: elem
			})
		}
	}

	const formatStrDate = strDate => {
		return new Intl.DateTimeFormat("fr-FR", {
			hour: "2-digit",
			minute: "2-digit",
			formatMatcher: "basic"
		}).format(new Date(strDate))
	}

	useEffect(() => {

		const newEvents = {}

		let currentDateForStr = new Date()

		let monthStr = currentDateForStr.getMonth() + 1

		if(monthStr.toString().length === 1)
			monthStr = "0" + monthStr

		let yearStr = currentDateForStr.getFullYear()

		setCurrentMonthStr(monthStr + "/" + yearStr)

		Axios.get("/api/calendar/events").then(res => {

			const oldEvents = res.data

			for (let i = 0; i < oldEvents.length; i++) {
				const oldEvent = oldEvents[i];

				const startDate = new Date(oldEvent.start.dateTime || oldEvent.start.date)

				let fullYear = startDate.getFullYear()
				let month = startDate.getMonth()
				let day = startDate.getDate()

				if(oldEvent.start.date && !oldEvent.start.dateTime
					&& oldEvent.end.date && !oldEvent.end.dateTime) {
						oldEvent.allDay = true
				} else {
					oldEvent.allDay = false
					oldEvent.startHourString = formatStrDate(oldEvent.start.dateTime)
					oldEvent.endHourString = formatStrDate(oldEvent.end.dateTime)
				}

				oldEvent.gradientId = Math.floor(Math.random() * Math.floor(5)) + 1 // Gradient id between 1 and 6

				//oldEvent.desc = Math.random() > 0.5 ? "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi libero dolor nemo sed incidunt. Repudia" : null

				newEvents[fullYear + '-' + month + '-' + day] = newEvents[fullYear + '-' + month + '-' + day] ? [...newEvents[fullYear + '-' + month + '-' + day], oldEvent] : [oldEvent]

			}

			setEvents(newEvents)

		}).catch(err => {
			console.log(err)
		})

	}, [])

	useEffect(() => {
		buildCalendar()
	}, [buildCalendar])

	return (
		<>
			<Helmet>
				<title>Calendrier des évènements BFR</title>
			</Helmet>

			<div className="calendar-page container background-grey page-padding" onClick={removeEventPopup}>

				<h1 className="section-title center">
					<div className="title">Calendrier {currentMonthStr === null ? "" : " - " + currentMonthStr}</div>
				</h1>

				<div className="calendar-container">

					<div className="calendar-header">
						<div>Lundi</div>
						<div>Mardi</div>
						<div>Mercredi</div>
						<div>Jeudi</div>
						<div>Vendredi</div>
						<div>Samedi</div>
						<div>Dimanche</div>
					</div>

					<div className="calendar-days">

						{calendar.map((val, idx) => {

							if(!val.events)
								val.events = []

							return (
								<div key={idx + 1} className={"calendar-day" + (val.month === "current" ? "" : " not-current-month")}>
									<div className="day-number">{val.dayNumber ?? "0"}</div>

									<div className="event-list">

										{val.events.map(event => {

											return (
												<div key={event.id} className={"event-item gradient-" + (event.gradientId) + (selectedEvent && selectedEvent.id === event.id ? " is-selected" : "")} data-event-id={event.id} onClick={handleEventClick}>
													<div className="event-box">
														<div className="event-title">{event.summary}</div>
													</div>

													<div onClick={handleEventPopupClick} className={"event-popup" + (val.weekDay < 2 ? " right-popup" : "")}>
														<div className="event-popup-title">
															{event.summary}
														</div>

														<div className="event-popup-content">

															{!event.allDay &&
																<>
																	<div>
																		<div className="event-popup-label">Début</div>
																		<div className="event-popup-data">{event.startHourString}</div>
																	</div>

																	<div>
																		<div className="event-popup-label">Fin</div>
																		<div className="event-popup-data">{event.endHourString}</div>
																	</div>
																</>
															}
															{event.allDay &&
																<div className="center">
																	<div>Toute la journée</div>
																</div>
															}

														</div>

														{event.description &&
															<div className="event-popup-desc">
																{event.description}
															</div>
														}
													</div>
												</div>
											)
										})}

									</div>

								</div>
							)

						})}

					</div>

				</div>

			</div>
		</>
	)
}

export default Calendar