import React from 'react'

import './Input.css'

function Input (props) {
	const [value, setValue] = React.useState(props.defaultValue || "");
	const [focused, setFocused] = React.useState(false)

	const handleChange = (e) => {
		let {value} = e.target
		setValue(value)
		if(props.onChange) {
			props.onChange(value, props?.name)
		}
	}

	return (
		<div className={"form-group" + (focused ? ' is-focused' : '') + (value.length > 0 ? ' has-content' : '')}>
			<label htmlFor={props.label}>{props.label}</label>
			<input size="1" onBlur={(e) => setFocused(false)} onFocus={(e) => setFocused(true)} type={props.type ?? "text"} name={props.label} id={props.label} value={value} onChange={handleChange} />
			<span className="help">{props.help}</span>
			<span className="error"></span>
		</div>
	);
};

export default Input