import React from "react"
import { Helmet } from 'react-helmet'

const Error404 = props => {
	return (
		<>
			<Helmet>
				<title>Error 404</title>
			</Helmet>

			<div className="error-404-page page-padding">

				<div className="container">

					<h1 className="section-title">
						<div className="center title">Erreur 404</div>
					</h1>

				</div>

			</div>

		</>
	)
}

export default Error404