import React, { useEffect, useState } from "react"

import './Bracket.css'

import { GrScorecard } from "react-icons/gr"
import { VscDebugStart, VscDebugStop } from "react-icons/vsc"
import Popup from "../Popup/Popup"
import Input from "../Input/Input"
import Axios from "axios"
import { toast } from "react-toastify"
import classnames from "classnames"

const Bracket = ({ tournament, user, setTournament }) => {

    const [selectedMatch, setSelectedMatch] = useState()
    const [openedPopup, setOpenedPopup] = useState()
    const [formData, setFormData] = useState({})

    const handleFormChange = (name, value) => {
        setFormData(oldFormData => ({ ...oldFormData, [name]: value }))
    }

    const submitScore = () => {
        const score0 = formData.score0
        const score1 = formData.score1
        const match = selectedMatch

        Axios.patch("/api/tournaments/" + tournament._id + "/match/" + match.bracketNo, { match: { score: [score0, score1], started: false } }).then(res => {
            toast.success("Scores mis à jour")
            setTournament(res.data)
            setOpenedPopup()
        }).catch(err => {
            console.error(err)
            toast.error("Une erreur est survenue")
        })
    }

    const submitWinner = () => {
        const winnerIdx = formData.selectedPlayer

        if (winnerIdx !== 0 && winnerIdx !== 1)
            return toast.error("Aucun joueur selectionné")

        const winnerPlayer = selectedMatch.players[winnerIdx]

        Axios.patch("/api/tournaments/" + tournament._id + "/match/" + selectedMatch.bracketNo, { match: { winner: { ...winnerPlayer } } }).then(res => {
            toast.success("Gagnant mis à jour")
            setTournament(res.data)
            setOpenedPopup("")
        }).catch(err => {
            console.error(err)
            toast.error("Une erreur est survenue")
        })
    }

    const toggleStartedMatch = match => {
        Axios.patch("/api/tournaments/" + tournament._id + "/match/" + match.bracketNo, { match: { started: !match.started } }).then(res => {
            //toast.success("Status du match mis à jour")
            setTournament(res.data)
        }).catch(err => {
            console.error(err)
            toast.error("Une erreur est survenue")
        })
    }

    const selectPlayer = playerIdx => setFormData(oldFormData => ({ ...oldFormData, selectedPlayer: playerIdx }))

    useEffect(() => {
        setFormData(oldFormData => ({ ...oldFormData, score0: undefined, score1: undefined, selectedPlayer: undefined }))
    }, [selectedMatch])

    return (
        <>
            <Popup
                title="Ajoutez le score"
                show={openedPopup === "score-popup" && selectedMatch !== undefined}
                hidePopup={() => {
                    setSelectedMatch()
                    setOpenedPopup()
                }}
            >
                <div className="match-score-input-group">
                    <div>{selectedMatch?.players[0].username}</div>
                    <div>
                        <Input label="Score 1" onChange={(val) => handleFormChange("score0", val)} />
                    </div>
                </div>

                <div className="match-score-input-group">
                    <div>{selectedMatch?.players[1].username}</div>
                    <div>
                        <Input label="Score 2" onChange={(val) => handleFormChange("score1", val)} />
                    </div>
                </div>

                <div className="flex flex-center">
                    <div className="btn" onClick={submitScore}>Soumettre</div>
                </div>
            </Popup>

            <Popup
                title="Ajoutez le gagnant"
                show={openedPopup === "winner-popup" && selectedMatch !== undefined}
                hidePopup={() => {
                    setSelectedMatch()
                    setOpenedPopup()
                }}
            >
                <div className="match-winner-input-group">

                    <div
                        className={(formData.selectedPlayer === 0 ? " selected" : "")}
                        onClick={e => selectPlayer(0)}
                    >
                        <div>{selectedMatch?.players[0].username}</div>
                    </div>

                    <div
                        className={(formData.selectedPlayer === 1 ? " selected" : "")}
                        onClick={e => selectPlayer(1)}
                    >
                        <div>{selectedMatch?.players[1].username}</div>
                    </div>
                </div>

                <div className="flex flex-center">
                    <div className="btn" onClick={submitWinner}>Soumettre</div>
                </div>
            </Popup>

            <div className="tournament-bracket">

                <div className="round-list">
                    {tournament.rounds.map((round, roundIdx) => {

                        const matchHeight = 58

                        // Calculate css gap between matches (95 is the height of 1 match)
                        let matchesGap = (Math.pow(2, (roundIdx + 1)) * matchHeight / 2) - matchHeight

                        return (
                            <div className="round" key={roundIdx}>
                                <div className="round-title">Round {roundIdx + 1}</div>

                                <div className="match-list" style={{
                                    "--padding-top": (matchesGap - 6) + "px",
                                    "--match-height": matchHeight + "px",
                                    gap: matchesGap + "px"
                                }}>

                                    {round.matches.map((match, matchIdx) => {

                                        let hasPlayer1 = match.players[0] ? true : false
                                        let hasPlayer2 = match.players[1] ? true : false

                                        return (
                                            <div
                                                className={classnames(["match"], {
                                                    "hidden": !match.displayed,
                                                    "is-final": match.isFinal,
                                                    "is-small-final": match.isSmallFinal,
                                                    "is-started": match.started
                                                })}
                                                key={matchIdx}
                                            >

                                                <div className="match-before"></div>

                                                {user && user.admin &&
                                                    <div className="match-admin-controls">
                                                        {hasPlayer1 && hasPlayer2 &&
                                                            <>
                                                                <div className="icon" onClick={() => {
                                                                    setSelectedMatch(match)
                                                                    setOpenedPopup("score-popup")
                                                                }}>
                                                                    <GrScorecard size={15} />
                                                                </div>

                                                                <div className="icon" onClick={e => toggleStartedMatch(match)}>
                                                                    {match.started ? <VscDebugStop size={15} /> : <VscDebugStart size={15} />}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                }

                                                <div className="players">
                                                    <div
                                                        className={classnames(["player1"], {
                                                            "has-player": hasPlayer1,
                                                            "is-winner": hasPlayer1 && match.winner?._id === match.players[0]._id.toString(),
                                                            "is-looser": match.winner && match.winner._id !== match.players[0]._id.toString(),
                                                        })}
                                                    >
                                                        <div className="player-name">{hasPlayer1 ? match.players[0].username + " (" + match.players[0].plane + ")" : ""}</div>
                                                        {hasPlayer1 && <div className="score">{match.score[0]}</div>}
                                                    </div>

                                                    <div
                                                        className={classnames(["player2"], {
                                                            "has-player": hasPlayer2,
                                                            "is-winner": hasPlayer2 && match.winner?._id === match.players[1]._id.toString(),
                                                            "is-looser": match.winner && match.winner._id !== match.players[1]._id.toString(),
                                                        })}
                                                    >
                                                        <div className="player-name">{hasPlayer2 ? match.players[1].username + " (" + match.players[1].plane + ")" : ""}</div>
                                                        {hasPlayer2 && <div className="score">{match.score[1]}</div>}
                                                    </div>
                                                </div>

                                                <div className="match-after" style={{
                                                    height: (Math.floor(matchesGap / 2)) + 34 + "px"
                                                }}></div>

                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </>
    )
}

export default Bracket