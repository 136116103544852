import React from 'react'

function Card(props) {

	return(
		<div className={"card " + (props.noPadding ? " no-padding " : "") + (props.className ? props.className : "")}>
			{props.title &&
				<div className="card-title">
					{props.title}
				</div>
			}

			<div className="card-body">
				{props.children}
			</div>
		</div>
	)
}

export default Card