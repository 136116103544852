import React, { useEffect, useState } from "react"
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'
import Card from '../../../components/Card/Card'
import List from '../../../components/List/List'
import ListItem from '../../../components/ListItem/ListItem'
import axios from 'axios'
import socketIOClient from "socket.io-client"
import Popup from '../../../components/Popup/Popup'
import ListHeader from '../../../components/ListHeader/ListHeader'
import Input from '../../../components/Input/Input'
import { toast } from 'react-toastify'
import { formatTimestamp } from "../../../utils"


const AdminBans = props => {

    const [bannedPlayers, setBannedPlayers] = useState({ dcs: [], site: [] })
    const [selectedPlayerUcid, setSelectedPlayerUcid] = useState(null)
    const [onlinePlayers, setOnlinePlayers] = useState([])
    const [openedPopup, setOpenedPopup] = useState(false)
    const [formsData, setFormsData] = useState({})

    const reloadBannedPlayers = () => {
        axios.get("/api/server/banned-players").then(res => {
            setBannedPlayers(res.data.bannedPlayers)
        }).catch(err => {
            console.log(err)
        })
    }

    const reloadOnlinePlayers = () => {
        axios.get("/api/server/online-players").then(res => {
            setOnlinePlayers(res.data.connectedPlayers)
        }).catch(err => {
            console.log(err)
        })
    }

    const selectBanPlayer = (e, playerUcid) => {
        e.preventDefault()
        setSelectedPlayerUcid(playerUcid)
        setOpenedPopup("ban-popup")
    }

    const unbanPlayer = (e, unbanFromSite, data) => {
        e.preventDefault()

        axios.post("/api/server/unban-player", {
            unbanFromSite,
            data
        }).then(res => {
            toast.success("Joueur débanni")
        }).catch(err => {
            console.log(err)
        })
    }

    const handleFormChange = (inputName, val) => {
        setFormsData(oldFormsData => ({ ...oldFormsData, [inputName]: val }))
    }

    const banPlayer = () => {

        let ucid = selectedPlayerUcid

        if (ucid) {
            let reason = formsData["ban-" + ucid + "-message"]
            let banMonths = formsData["ban-" + ucid + "-months"]
            let banDays = formsData["ban-" + ucid + "-days"]
            let banHours = formsData["ban-" + ucid + "-hours"]

            let time = 0
            let permBan = false

            if (banMonths) {
                time += banMonths * (60 * 60 * 24 * 30)
            }

            if (banDays) {
                time += banMonths * (60 * 60 * 24)
            }

            if (banHours) {
                time += banMonths * (60 * 60)
            }

            if (time === 0) {
                permBan = true
            }

            axios.post("/api/server/ban-player", {
                ucid,
                reason,
                time,
                permBan
            }).then(res => {
                toast.success("Le joueur à bien été banni")
                setOpenedPopup("")
            }).catch(err => {
                console.log(err)
            })
        }

    }

    useEffect(() => {

        reloadBannedPlayers()
        reloadOnlinePlayers()

        const socket = socketIOClient(axios.defaults.baseURL)

        socket.on("players-bans-list", reloadBannedPlayers)
        socket.on("online-players", reloadOnlinePlayers)

        return function () {
            socket.disconnect()
        }

    }, [])

    return (
        <>
            <Popup title="Bannir le joueur" show={(openedPopup === "ban-popup")} hidePopup={() => setOpenedPopup(false)}>
                <div className="flex flex-column space-around flex-1">
                    <div>
                        <Input label="Message" defaultValue={formsData["ban-" + selectedPlayerUcid + "-message"]} onChange={(val) => handleFormChange("ban-" + selectedPlayerUcid + "-message", val)} />
                    </div>

                    <div>
                        <div className="center">Laisser un temps vide pour un ban permanent</div>
                        <div className="inputs-row">
                            <Input label="Mois" type="number" defaultValue={formsData["ban-" + selectedPlayerUcid + "-months"]} onChange={(val) => handleFormChange("ban-" + selectedPlayerUcid + "-months", val)} />
                            <Input label="Jours" type="number" defaultValue={formsData["ban-" + selectedPlayerUcid + "-days"]} onChange={(val) => handleFormChange("ban-" + selectedPlayerUcid + "-days", val)} />
                            <Input label="Heures" type="number" defaultValue={formsData["ban-" + selectedPlayerUcid + "-hours"]} onChange={(val) => handleFormChange("ban-" + selectedPlayerUcid + "-hours", val)} />
                        </div>
                    </div>

                    <div className="btn-group center">
                        <div className="btn btn-danger btn-small" onClick={(e) => banPlayer()}>Bannir</div>
                        <div className="btn btn-success btn-small" onClick={(e) => setOpenedPopup(false)}>Annuler</div>
                    </div>
                </div>
            </Popup>

            <div className="sidebar-container admin-dashboard-page background-grey">
                <AdminSidebar className="page-padding" />

                <div className="sidebar-container-content page-padding">

                    <h2 className="section-title">
                        Bannissements
                    </h2>

                    <div className="cards-container">

                        <div className="cards-container flex-1 flex-column">
                            <Card title="Liste des bannis" noPadding>
                                <List>
                                    {(bannedPlayers.site.length === 0 && bannedPlayers.dcs.length) === 0 ?
                                        <ListItem left="Aucun joueur n'est banni" /> : <ListHeader left="Nom du joueur" middle="Ban jusqu'au" right="" />
                                    }
                                    {bannedPlayers.dcs.map(player => {
                                        return (
                                            <ListItem
                                                key={player.ucid}
                                                to={"/player/" + player.ucid}
                                                left={player.name}
                                                middle={formatTimestamp(player.banned_until * 1000)}
                                                right={<div className="pointer btn btn-small btn-success" onClick={e => unbanPlayer(e, false, player)}>Débannir</div>}
                                            />
                                        )
                                    })}

                                    {bannedPlayers.site.map(ban => {
                                        return (
                                            <ListItem
                                                key={ban.ucid}
                                                to={"/player/" + ban.ucid}
                                                left={ban.name}
                                                middle={ban.permBan ? "Permanent" : formatTimestamp(ban.endTime)}
                                                right={<div className="pointer btn btn-small btn-success" onClick={e => unbanPlayer(e, true, ban)}>Débannir</div>}
                                            />
                                        )
                                    })}
                                </List>
                            </Card>
                        </div>

                    </div>

                    <div className="cards-container">

                        <div className="cards-container flex-1 flex-column">
                            <Card title="Bannir un joueur" noPadding>
                                <List>
                                    {Object.keys(onlinePlayers).map(playerKey => {
                                        const player = onlinePlayers[playerKey]
                                        return (
                                            <ListItem key={player.id} left={player.name} right={player.id !== 1 ? <div onClick={(e) => selectBanPlayer(e, player.ucid)} className="pointer btn btn-small btn-danger">Bannir</div> : null} />
                                        )
                                    })}
                                </List>
                            </Card>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}
export default AdminBans