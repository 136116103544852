import React from "react"
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'

const AdminMap = props => {

	return (
		<>
			<div className="sidebar-container admin-dashboard-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Map
					</h2>

					<div className="cards-container">

						En cours de développement ...

					</div>

				</div>

			</div>
		</>
	)
}
export default AdminMap