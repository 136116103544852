import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import AuthService from "../../services/AuthService"
import { HashLink } from 'react-router-hash-link';

import ServerStatus from '../ServerStatus/ServerStatus';

import BFRLogo from '../../assets/img/bfr-animated-logo.gif'
//import BFRLogo from '../../assets/img/logo-bfr.png'

import './Header.css'
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import DarkThemeSwitcher from '../DarkThemeSwitcher/DarkThemeSwitcher';

/*

	Infos Serveur
		- Serveur BFR
			- Server Status
			- Players onlines
			- Lotatc online
			- Graphique fréquentations

		- Phraséologie (Plus lien LotAtc)
		- SRS (Tutos)

	Stats
	Tacview
	Galerie
	Classement
	Calendrier

*/

let defaultTransparent = false

function Header(props) {

	const history = useHistory()

	const [menuOpened, setMenuOpened] = useState(false)
	const [user, setUser] = useState(null)
	const [transparent, setTransparent] = useState(defaultTransparent)

	const reloadUser = () => {
		setUser(AuthService.getCurrentUser())
	}

	const handleLogout = () => {
		AuthService.logout()
	}

	const handleScroll = useCallback((e) => {

		const scrollY = window.scrollY

		if(scrollY > 150) {
			if(transparent)
				setTransparent(false)
		} else {
			setTransparent(defaultTransparent)
		}

	}, [transparent])

	const updatePath = useCallback(() => {

		let { pathname } = history.location

		if(pathname === "/" || pathname === "/stats")
			defaultTransparent = true
		else
			defaultTransparent = false

		handleScroll()

	}, [handleScroll, history])

	const closeMenu = () => {
		setMenuOpened(false)
	}

	const toggleMenu = () => {
		setMenuOpened(!menuOpened)
	}

	useEffect(() => {

		updatePath()

		let unlisten = history.listen(updatePath)

		return function () {
			unlisten()
		}

	}, [history, updatePath])

	useEffect(() => {

		handleScroll()

		window.addEventListener("scroll", handleScroll)

		setUser(AuthService.getCurrentUser())

		AuthService.addEventListener("signin", reloadUser)
		AuthService.addEventListener("logout", reloadUser)

		return function () {
			AuthService.removeEventListener("signin", reloadUser)
			AuthService.removeEventListener("logout", reloadUser)
		}
	}, [handleScroll])

	return (
		<header className={"header container" + (transparent ? ' header-transparent' : '') + (menuOpened ? ' is-opened' : '')}>
			<Link to="/" className="logo">
				<img src={BFRLogo} alt="Logo BFR" /><span>BFR</span>
			</Link>

			<nav className="nav">

				<DropdownMenu title="Infos Serveur" className="header-hover" titleTo="/infos-server">

					<HashLink to="/infos-server#bfr-server" onClick={closeMenu}>Serveur BFR</HashLink>
					<HashLink to="/infos-server#phraseology" onClick={closeMenu}>Phraséologie</HashLink>
					<HashLink to="/infos-server#srs" onClick={closeMenu}>SRS</HashLink>

				</DropdownMenu>

				<Link to="/stats" onClick={closeMenu} className="header-hover">Stats</Link>
				<Link to="/tacview" onClick={closeMenu} className="header-hover">Tacview</Link>
				<Link to="/tournaments" onClick={closeMenu} className="header-hover">Tournois</Link>

				<Link to="/gallery" onClick={closeMenu} className="header-hover">Galerie</Link>
				{/* <Link to="/ranking" onClick={closeMenu}>Classement</Link> */}
				<Link to="/calendar" onClick={closeMenu} className="header-hover">Calendrier</Link>
				{user && user.admin &&
					<Link to="/admin/dashboard" onClick={closeMenu}>Administration</Link>
				}
				{user &&
					<>
						{/*<Link to="/user/dashboard">Mon compte</Link>*/}
						<div className="pointer" onClick={() => {
							handleLogout()
							closeMenu()
						}} >Se déconnecter</div>
					</>
				}
				<ServerStatus />
			</nav>

			<DarkThemeSwitcher />

			<div className="hamburger-icon" onClick={toggleMenu}>
				<span></span>
				<span></span>
				<span></span>
			</div>

			<div className="menu-overlay" onClick={closeMenu}></div>

		</header>
	)
}

export default Header