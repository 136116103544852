import React, { useEffect } from 'react'
import Parallax from 'parallax-js'

import './Stats.css'

import SearchBar from '../../components/SearchBar/SearchBar'

import BGParallax6 from '../../assets/img/bg_parallax_6_compressed.png'

import Plane1 from '../../assets/img/plane_1_compressed.png'
import Plane2 from '../../assets/img/plane_2_compressed.png'
import { Helmet } from 'react-helmet'

function Stats() {

	useEffect(() => {

		let scene = document.getElementById('parallax-banner');
		new Parallax(scene);

	}, [])

	return(
		<>
			<Helmet>
				<title>Statistiques du serveur BFR</title>
			</Helmet>

			<div className="stats-page">

				<div className="background-banner">

					<div className="parallax-banner" id="parallax-banner">
						<div data-depth="0.20"><div className="background" style={{backgroundImage: `url(${BGParallax6})`}}></div></div>
						<div data-depth="0.25"><div className="plane-parallax" id="plane-1" style={{backgroundImage: `url(${Plane1})`}}></div></div>
						<div data-depth="0.40"><div className="plane-parallax" id="plane-2" style={{backgroundImage: `url(${Plane2})`}}></div></div>
					</div>

					<SearchBar />

				</div>

			</div>
		</>
	)
}

export default Stats