import React, { useState } from 'react'
import Input from '../../components/Input/Input'
import { useHistory } from 'react-router-dom'
import AuthService from "../../services/AuthService"

import { toast } from 'react-toastify'

import './Login.css'
import { Helmet } from 'react-helmet'

function Login(props) {

	const history = useHistory()

	const [formValues, setFormValues] = useState({
		username: "",
		pass: ""
	})

	const handleFormChange = (input, value) => {
		setFormValues({ ...formValues, [input]: value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		AuthService.login(formValues.username, formValues.pass).then(
			() => {
				let user = AuthService.getCurrentUser()
				toast.success("Bonjour, " + user.username)
				history.push("/admin/dashboard")
			},
			error => {
				const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.error) ||
				error.error ||
				error.toString();

				toast.error(resMessage)
			}
	  	);


	}

	return (
		<>
			<Helmet>
				<title>Se connecter</title>
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className="container page-padding login-page">
				<h1 className="section-title">
					Se connecter
				</h1>

				<form className="login-form" onSubmit={handleSubmit}>

					<Input label="Identifiant" onChange={(val) => handleFormChange("username", val)} />

					<Input label="Mot de passe" type="password" onChange={(val) => handleFormChange("pass", val)} />

					<div className="center">
						<button type="submit" className="btn">Se connecter</button>
					</div>

				</form>

				<p>La connexion est pour le moment réservée aux administrateurs</p>
			</div>
		</>
	)
}

export default Login