import React from "react"

function UserDashboard(props) {

	return (
		<>
			<div className="player-dashboard-page background-grey container page-padding">
				User Dashboard
			</div>
		</>
	)
}

export default UserDashboard