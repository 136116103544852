import Axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import Bracket from "../../../components/Bracket/Bracket"
import AuthService from "../../../services/AuthService"
import "../Tournaments.css"
import classnames from "classnames"
import { Link, useLocation, useParams } from "react-router-dom"

import { AiFillDelete, AiOutlineCheck, AiOutlineClose } from "react-icons/ai"
import Popup from "../../../components/Popup/Popup"
import Input from "../../../components/Input/Input"

import { debounce } from "../../../utils"
import Textarea from "../../../components/Textarea/Textarea"

const TournamentView = props => {
    const query = new URLSearchParams(useLocation().search)

    const fullscreen = query.get("fullscreen")

    const { tournamentId } = useParams()

    const [currentTab, setCurrentTab] = useState("bracket")
    const [formData, setFormData] = useState({})
    const [user, setUser] = useState({})
    const [tournament, setTournament] = useState()
    const [selectedPlayer, setSelectedPlayer] = useState()

    const handleInputChange = (value, name) => {
        setFormData(oldFormData => ({ ...oldFormData, [name]: value }))
    }

    const removePlayer = (e, player) => {
        e.stopPropagation()

        Axios.patch(
            "/api/tournaments/" + tournamentId + "/players/" + player._id,
            { player: { validated: false } }
        )
            .then(res => {
                toast.success("Joueur retiré")
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const validPlayer = (e, player) => {
        e.stopPropagation()

        Axios.patch(
            "/api/tournaments/" + tournamentId + "/players/" + player._id,
            { player: { validated: true } }
        )
            .then(res => {
                toast.success("Joueur validé")
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const deletePlayer = (e, player) => {
        e.stopPropagation()

        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce joueur ?")) {

            console.log("Deleting player", player)

            Axios.delete("/api/tournaments/" + tournamentId + "/players/" + player._id)
                .then(res => {
                    toast.success("Joueur supprimé")
                    setTournament(res.data)
                })
                .catch(err => {
                    console.error(err)
                    toast.error("Une erreur est survenue")
                })
        }

    }

    const setRegistrationOpen = toOpen => {
        Axios.patch("/api/tournaments/" + tournamentId, {
            tournament: { registrationOpen: toOpen }
        })
            .then(res => {
                setTournament(res.data)

                if (toOpen)
                    toast.success("Les inscriptions ont bien été ouvertes")
                else toast.success("Les inscriptions ont bien été fermées")
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const startTournament = () => {
        if (tournament.players.length < 4)
            return toast.error(
                "Le tournoi n'a pas assez de joueurs pour commencer"
            )

        Axios.post("/api/tournaments/" + tournamentId + "/start", {})
            .then(res => {
                toast.success("Le tournoi est démarré")
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const endTournament = () => {
        Axios.patch("/api/tournaments/" + tournament._id, {
            tournament: { status: "ended", endAt: new Date() }
        })
            .then(res => {
                toast.success("Le tournoi est terminé")
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const exportTournament = () => {
        Axios.get("/api/tournaments/" + tournament._id + "/export")
            .then(res => {
                const { filename } = res.data

                const downloadUrl = "/api/download/" + filename

                Axios.get(downloadUrl, { responseType: "blob" })
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute("download", res.data.filename)
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const shufflePlayers = () => {
        return new Promise((resolve, reject) => {
            const players = [...tournament.players]

            players.sort((player1, player2) => {
                const player1Order = player1.order ?? 0
                const player2Order = player2.order ?? 0

                if (!player1Order && !player2Order)
                    return Math.random() > 0.5 ? 1 : -1

                if (player1Order > player2Order) return -1

                return 1
            })

            Axios.patch("/api/tournaments/" + tournament._id, {
                tournament: { players }
            })
                .then(res => {
                    toast.success(
                        "L'ordre des joueurs est maintenant aléatoire"
                    )
                    setTournament(res.data)
                    resolve()
                })
                .catch(err => {
                    console.error(err)
                    toast.error("Une erreur est survenue")
                    reject(err)
                })
        })
    }

    const debouncedSetPlayerOrder = useCallback(
        debounce((player, newOrder) => {
            if (!newOrder) newOrder = undefined

            Axios.patch(
                "/api/tournaments/" +
                tournament._id +
                "/players/" +
                player?._id,
                { player: { order: newOrder } }
            )
                .then(res => {
                    toast.success("L'ordre à été modifié")
                    setTournament(res.data)
                })
                .catch(err => {
                    console.error(err)
                    toast.error("Une erreur est survenue")
                })
        }, 700),
        [tournament]
    )

    const handlePlayerOrderChange = (player, newOrder) => {
        setFormData(oldFormData => ({
            ...oldFormData,
            ["player-" + player._id + "-order"]: newOrder
        }))
        debouncedSetPlayerOrder(player, newOrder)
    }

    const applyTournamentModifications = () => {
        const newTournament = {}

        if (formData.tournamentName)
            newTournament.tournamentName = formData.tournamentName

        if (formData.description)
            newTournament.description = formData.description

        if (formData.videoYtId) newTournament.videoYtId = formData.videoYtId

        console.log("Send ", newTournament)

        Axios.patch("/api/tournaments/" + tournamentId, {
            tournament: newTournament
        })
            .then(res => {
                console.log(res)
                toast.success("Tournoi mis à jour")
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error("Une erreur est survenue")
            })
    }

    const correctTab = () => {
        switch (currentTab) {
            case "presentation":
                return (
                    <div>
                        {tournament.description && (
                            <div className="tournament-description">
                                {tournament.description}
                            </div>
                        )}

                        {tournament.videoYtId && (
                            <div className="tournament-video">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={
                                        "https://www.youtube.com/embed/" +
                                        tournament.videoYtId
                                    }
                                    allowfullscreen="allowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen"
                                    oallowfullscreen="oallowfullscreen"
                                    webkitallowfullscreen="webkitallowfullscreen"
                                    title="Présentation video"
                                ></iframe>
                            </div>
                        )}
                    </div>
                )

            case "settings":
                return (
                    <div className="tournament-view-admin-controls">
                        <div className="controls-btn-group">
                            {tournament.registrationOpen ? (
                                <div
                                    className="btn"
                                    onClick={e => setRegistrationOpen(false)}
                                >
                                    Fermer les inscriptions
                                </div>
                            ) : (
                                <div
                                    className="btn"
                                    onClick={e => setRegistrationOpen(true)}
                                >
                                    Ouvrir les inscriptions
                                </div>
                            )}

                            {tournament.status === "started" ? (
                                <div className="btn" onClick={endTournament}>
                                    Tournoi terminé
                                </div>
                            ) : (
                                <div className="btn" onClick={startTournament}>
                                    Démarrer le tournoi
                                </div>
                            )}

                            <div className="btn" onClick={exportTournament}>
                                Exporter le tournoi
                            </div>
                        </div>

                        <div>
                            <Input
                                label="Nom du tournoi"
                                defaultValue={tournament.name}
                                onChange={handleInputChange}
                                name="tournamentName"
                            />

                            <Textarea
                                label="Description"
                                defaultValue={tournament.description}
                                onChange={handleInputChange}
                                name="description"
                            />

                            <Input
                                label="Video Youtube"
                                defaultValue={tournament.videoYtId}
                                onChange={handleInputChange}
                                name="videoYtId"
                            />

                            <div className="flex">
                                <div
                                    className="btn"
                                    onClick={applyTournamentModifications}
                                >
                                    Appliquer
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case "players":
                let tournamentPlayers = tournament.players

                if (tournament.status === "ended")
                    tournamentPlayers.sort((player1, player2) =>
                        player1.classement > player2.classement ? 1 : -1
                    )

                let toReturn = tournamentPlayers.map(player => {
                    const playerOrder = formData[
                        "player-" + player._id + "-order"
                    ]
                        ? formData["player-" + player._id + "-order"]
                        : player.order

                    const onClickFunction =
                        user && user.admin ? setSelectedPlayer : () => { }

                    return (
                        <div
                            key={player._id}
                            className={classnames(
                                ["tournament-player-item mb-20"],
                                {
                                    validated: player.validated,
                                    pointer: user && user.admin
                                }
                            )}
                            onClick={e => onClickFunction(player)}
                        >
                            <div>{player.username}</div>

                            {user &&
                                user.admin &&
                                tournament.status !== "ended" && (
                                    <>
                                        <input
                                            type="number"
                                            value={playerOrder ?? 0}
                                            onChange={e =>
                                                handlePlayerOrderChange(
                                                    player,
                                                    e.target.value
                                                )
                                            }
                                        />

                                        <AiFillDelete
                                            className="pointer"
                                            onClick={e =>
                                                deletePlayer(e, player)
                                            }
                                        />

                                        {player.validated ? (
                                            <AiOutlineClose
                                                className="pointer"
                                                onClick={e =>
                                                    removePlayer(e, player)
                                                }
                                            />
                                        ) : (
                                            <AiOutlineCheck
                                                className="pointer"
                                                onClick={e =>
                                                    validPlayer(e, player)
                                                }
                                            />
                                        )}
                                    </>
                                )}

                            {tournament.status === "ended" && (
                                <div>{player.classement}</div>
                            )}
                        </div>
                    )
                })
                return (
                    <div>
                        {user && user.admin && tournament.status === "pending" && (
                            <>
                                <div className="flex mb-20">
                                    <div
                                        className="btn"
                                        onClick={shufflePlayers}
                                    >
                                        Randomize
                                    </div>
                                </div>
                            </>
                        )}
                        {tournament.registrationOpen && (
                            <div className="mb-20">
                                <Link
                                    to={
                                        "/tournaments/" +
                                        tournamentId +
                                        "/register"
                                    }
                                    className="btn"
                                >
                                    S'inscrire
                                </Link>
                            </div>
                        )}

                        <div>
                            {toReturn.length ? (
                                toReturn
                            ) : (
                                <p>Aucun joueur inscrit pour le moment</p>
                            )}
                        </div>
                    </div>
                )
            default:
                break
        }

        // For tab bracket and default if switch above doesnt return
        if (tournament.status === "started" || tournament.status === "ended")
            return (
                <div>
                    {user && user.admin && (
                        <div
                            style={{
                                display: "inline-block",
                                marginBottom: "10px"
                            }}
                        >
                            <div
                                className="btn"
                                onClick={async () => {
                                    if (
                                        window.confirm(
                                            "Voulez-vous vraiment recalculer ce tournoi ?"
                                        )
                                    ) {
                                        await shufflePlayers()
                                        startTournament()
                                    }
                                }}
                            >
                                Recalculer
                            </div>
                        </div>
                    )}

                    <Bracket
                        setTournament={setTournament}
                        user={user}
                        tournament={tournament}
                    />
                </div>
            )
        else return <p>Le tournoi n'a pas encore commencé.</p>
    }

    const reloadTournament = useCallback(() => {
        if (tournament?.status === "ended") return

        Axios.get("/api/tournaments/" + tournamentId)
            .then(res => {
                setTournament(res.data)
            })
            .catch(err => {
                console.error(err)
                toast.error(
                    "Une erreur est survenue lors du chargement du tournoi"
                )
            })
    }, [tournament?.status, tournamentId])

    useEffect(() => {
        reloadTournament()
    }, [reloadTournament, tournamentId])

    useEffect(() => {
        setUser(AuthService.getCurrentUser())

        let reloadInterval

        if (tournament?.status !== "ended")
            reloadInterval = setInterval(reloadTournament, 20000)

        return function () {
            clearInterval(reloadInterval)
        }
    }, [reloadTournament, tournament?.status])

    return (
        <>
            <Helmet>
                <title>Détails du tournoi BFR</title>
                <meta
                    name="description"
                    content="Toutes les informations sur un tournoi"
                />
            </Helmet>

            <Popup
                title={"Informations du joueur " + selectedPlayer?.username}
                show={selectedPlayer !== undefined}
                hidePopup={() => setSelectedPlayer(undefined)}
            >
                {selectedPlayer && (
                    <div className="selected-player-popup">
                        <div>Pseudo : {selectedPlayer.username}</div>
                        <div>Team : {selectedPlayer.team}</div>
                        <div>Maps : {selectedPlayer.maps?.join(", ")}</div>
                        <div>Avion : {selectedPlayer.plane}</div>
                        <div>Présentation : {selectedPlayer.presentation}</div>
                        <div>Setup : {selectedPlayer.setup}</div>
                        <div>Remarque : {selectedPlayer.remark}</div>
                    </div>
                )}
            </Popup>

            <div className="tournament-view-page page-padding">
                <div className="container">
                    {tournament && (
                        <>
                            {!fullscreen && (
                                <>
                                    <h1 className="section-title">
                                        <div className="center title">
                                            Tournoi {tournament.name}
                                        </div>
                                    </h1>

                                    <div className="tournament-nav-container mb-20">
                                        {(tournament.description ||
                                            tournament.videoYtId) && (
                                                <div
                                                    className={classnames(
                                                        ["tournament-nav-item"],
                                                        {
                                                            selected:
                                                                currentTab ===
                                                                "presentation"
                                                        }
                                                    )}
                                                    onClick={e =>
                                                        setCurrentTab(
                                                            "presentation"
                                                        )
                                                    }
                                                >
                                                    Présentation
                                                </div>
                                            )}

                                        <div
                                            className={classnames(
                                                ["tournament-nav-item"],
                                                {
                                                    selected:
                                                        currentTab === "bracket"
                                                }
                                            )}
                                            onClick={e =>
                                                setCurrentTab("bracket")
                                            }
                                        >
                                            Tournoi
                                        </div>

                                        <div
                                            className={classnames(
                                                ["tournament-nav-item"],
                                                {
                                                    selected:
                                                        currentTab === "players"
                                                }
                                            )}
                                            onClick={e =>
                                                setCurrentTab("players")
                                            }
                                        >
                                            Joueurs
                                        </div>

                                        {user && user.admin && (
                                            <>
                                                <div
                                                    className={classnames(
                                                        ["tournament-nav-item"],
                                                        {
                                                            selected:
                                                                currentTab ===
                                                                "settings"
                                                        }
                                                    )}
                                                    onClick={e =>
                                                        setCurrentTab(
                                                            "settings"
                                                        )
                                                    }
                                                >
                                                    Paramètres
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}

                            <div>{correctTab()}</div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default TournamentView
