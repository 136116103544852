import Axios from 'axios'
import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import AdminSidebar from '../../../components/AdminSidebar/AdminSidebar'

import './Logs.css'

const AdminLogs = props => {

	const [logs, setLogs] = useState()

	let {logName} = useParams()

	useEffect(() => {

		setLogs()

		if(!logName) {
			return
		}

		Axios.get("/api/logs/" + logName).then(res => {

			setLogs(res.data)

		}).catch(err => {
			console.log(err)
		})

	}, [logName])

	return (
		<>
			<div className="sidebar-container admin-logs-page background-grey">
				<AdminSidebar className="page-padding" />

				<div className="sidebar-container-content page-padding">

					<h2 className="section-title">
						Logs
					</h2>

					<div className="log-container">

						{logs &&
							<div className="logs-box">
								{logs}
							</div>
						}

					</div>

				</div>

			</div>
		</>
	)
}
export default AdminLogs