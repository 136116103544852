import React from 'react'
import { Link } from 'react-router-dom'

function ListHeader(props) {

	const getChildren = () => {
		return (
			<>
				{props.left !== undefined &&
					<div className="left">
						{props.left}
					</div>
				}
				{props.middle !== undefined &&
					<div className="middle">
						{props.middle}
					</div>
				}
				{props.right !== undefined &&
					<div className="right">
						{props.right}
					</div>
				}
			</>
		)
	}

	let htmlContent = ""

	if(props.to) {
		htmlContent = <Link to={props.to} className="list-header">{getChildren()}</Link>
	} else {
		htmlContent = <div className="list-header">{getChildren()}</div>
	}

	return htmlContent
}

export default ListHeader