import axios from "axios";

class AuthService {

	static events = {};
	static renewTokenTimeout = null

    static addEventListener(name, handler) {
        if (this.events.hasOwnProperty(name))
			this.events[name].push(handler);
        else
			this.events[name] = [handler];
    }

    static removeEventListener(name, handler) {
        /* This is a bit tricky, because how would you identify functions?
           This simple solution should work if you pass THE SAME handler. */
        if (!this.events.hasOwnProperty(name))
            return;

        var index = this.events[name].indexOf(handler);
        if (index !== -1)
			this.events[name].splice(index, 1);
    }

    static fireEvent(name, args) {
        if (!this.events.hasOwnProperty(name))
            return;

        if (!args || !args.length)
            args = [];

        var evs = this.events[name], l = evs.length;
        for (var i = 0; i < l; i++) {
            evs[i].apply(null, args);
        }
    }

	static login(username, pass) {

		return axios
			.post("/api/auth/signin", {
				username,
				pass
			})
			.then(response => {
				if (response.data.accessToken) {
					const data = response.data
					const user = {
						admin: data.admin,
						email: data.email ?? null,
						username: data.username
					}

					localStorage.setItem("user", JSON.stringify(user))
					localStorage.setItem("accessToken", response.data.accessToken)
					this.fireEvent("signin", { user })

					axios.defaults.headers.common['x-access-token'] = response.data.accessToken

					clearTimeout(this.renewTokenTimeout)

					this.renewTokenTimeout = setTimeout(() => {
						this.renewToken()
					}, 3600000) // Every hour

					return user
				}

				return response.data;
			});
	}

	static logout() {
		localStorage.removeItem("user")
		localStorage.removeItem("accessToken")
		axios.defaults.headers.common['x-access-token'] = null
		this.fireEvent("logout")
	}

	static getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	static getAccessToken() {
		return localStorage.getItem('accessToken');
	}

	static checkLogged() {
		let accessToken = this.getAccessToken()

		if(accessToken) {
			axios.defaults.headers.common['x-access-token'] = accessToken
			return true
		}
		return false
	}

	static renewToken() {

		return axios
			.get("/api/auth/renew-token")
			.then(res => {
				let accessToken = res.data.accessToken

				localStorage.setItem("accessToken", accessToken)
				this.fireEvent("renew-token", { accessToken })
				axios.defaults.headers.common['x-access-token'] = accessToken

				clearTimeout(this.renewTokenTimeout)

				this.renewTokenTimeout = setTimeout(() => {
					this.renewToken()
				}, 3600000) // Every hour

			}).catch(err => {
				console.log(JSON.stringify(err))

				if(err?.response?.status === 401) {
					// JWT Expired
					this.logout()
				}
			})
	}
}

export default AuthService