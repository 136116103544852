import React, { useRef } from "react"
import { CSSTransition } from 'react-transition-group';

import './Popup.css'

const Popup = props => {
	const nodeRef = useRef(null)

	const hidePopup = () => {
		props.hidePopup()
	}

	return (
		<>
			<CSSTransition
				nodeRef={nodeRef}
				in={props.show}
				timeout={300}
				classNames="popup"
				unmountOnExit
			>
				<div ref={nodeRef}>
					<div className="popup-overlay" onClick={() => hidePopup()}></div>
					<div className={"popup" + (props.className ? " " + props.className : "")}>
						{props.title &&
							<div className="popup-header">
								{props.title}
							</div>
						}

						<div className="popup-body">
							{props.children}
						</div>
					</div>
				</div>
			</CSSTransition>
		</>
	)
}

export default Popup