import React from 'react'
import { Link } from 'react-router-dom'

function ListItem(props) {

	const getChildren = () => {
		return (
			<>
				{props.left !== undefined &&
					<div className="left">
						{props.left}
					</div>
				}
				{props.middle !== undefined &&
					<div className="middle">
						{props.middle}
					</div>
				}
				{props.right !== undefined &&
					<div className="right">
						{props.right}
					</div>
				}
			</>
		)
	}

	let htmlContent = ""

	if(props.to) {

		if(props.to.startsWith("http")) {
			htmlContent =   <a
								href={props.to}
								className={"list-item " + (props.className ? props.className : "")}
								onContextMenu={props.onContextMenu}
								onClick={props.onClick}
							>
								{getChildren()}
							</a>
		} else {
			htmlContent =   <Link
								to={props.to}
								className={"list-item " + (props.className ? props.className : "")}
								onContextMenu={props.onContextMenu}
								onClick={props.onClick}
							>
								{getChildren()}
							</Link>
		}


	} else {
		htmlContent =   <div
							className={"list-item " + (props.className ? props.className : "")}
							onContextMenu={props.onContextMenu}
							onClick={props.onClick}
						>
							{getChildren()}
						</div>
	}

	return htmlContent
}

export default ListItem